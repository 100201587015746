import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import Loader from 'components/UI/Loader';
import { useDispatchApp } from 'redux/rootSelectors';
import { featchAllCreativeTypesAction, fetchCreativesList } from 'domains/creative/reduser';
import { TID } from 'types/general';
import { useRouteMatch } from 'react-router';
import Statistics from './Statistics';
import Links from './Links';
import ErrorReport from '../../../components/Errors/Reports';
import HeaderCampaign from '../HeaderCampaign';
import css from './styles.module.scss';
import { useCampaignAdditional } from './hooks';
import Pretargeting from '../../../components/Pretargeting';
import Special from './Special';

const Additional: FC = () => {
  const {
    campaign,
    isLoading,
    title,
    preTargeting,
    isLoadingPreTarget,
    handlerSetPretargeting,
    error,
  } = useCampaignAdditional();

  const dispatch = useDispatchApp();
  const { params } = useRouteMatch<TID>();

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction());
    dispatch(
      fetchCreativesList({
        campaign_xxhash: params.id,
      }),
    );
  }, []);

  if (error.isError) {
    return <ErrorReport error={error} />;
  }
  if (isLoading && preTargeting || isLoadingPreTarget || !preTargeting) {
    return <Loader />;
  }

  return (
    <div className={css.additional}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <HeaderCampaign campaign={campaign} isLoading={isLoading} />

      <div className={css.content}>
        <div className={cn(css.container, 'container')}>
          <Pretargeting
            handlerSave={handlerSetPretargeting}
            preTargeting={preTargeting}
          />
          <Statistics />
          <Special />
          <Links />
        </div>
      </div>
    </div>
  );
};

export default Additional;
