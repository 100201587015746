export default {
  translations: {
    error_page: {
      err404: {
        title: 'Error 404. Page not found',
        text: 'Try starting with main page.',
        button: 'Go Back',
      },
      err500: {
        title: 'Error 500',
        text: 'Sorry, we got an error',
        button: 'Back to home page',
      },
      errNC: {
        title: 'No internet connection',
        text: 'You seem to be having problems connecting to the network.',
      },
      errUC: {
        title: 'Technical work is underway.',
        text: 'Everything will work very soon. We apologize for any inconvenience caused.',
      },
      errAB: {
        title: 'We noticed that you`re using an ad blocker.',
        text: 'It blocks website functionality and prevents the page from fully loading. Please disable Adblock browser extensions for our domain to have TD site access.',
      },
    },
    errors: {
      upload: {
        e1: 'Invalid file format. Zip required',
        e2: 'Invalid file format. Requires png, jpeg, jpg, gif, svg',
        e3: 'File size exceeds 150 kb',
        e4: 'File size exceeds',
        e5: 'Invalid file format. Requires ',
      },
      unknown_error: 'Unknown error in method',
      formdom: 'Invalid domain or URL format.',
      additional_cat_err:
        'This category can significantly limit available inventory',
      err_http_lorem:
        'Invalid link format. Use http:// or https:// or www. or {LOREM}',
      err_http_lorem2: 'Invalid link format. Use http:// or https://',
      limitsym: 'Maximum character limit is exceeded.',
      percent_limit: 'Values up to 100 are possible',
      parameter_d_n_match:
        "The value of ID transfer parameter doesn't correspond to the reference value",
      f1_d_n_cont_id:
        "1F link doesn't contain the parameter of impression transfer ID",
      limitsym10000: '10000 ',
      limit_symbol: {
        start: 'A numerical value has been exceeded. Max calue is ',
        pixel: ' pixels',
      },
      err_arh: 'Invalid file format. ZIP archive required',
      err_value: 'Invalid value',
      err1: 'System error',
      err_file: 'File upload error. \n',
      err40: 'The file name is not valid.',
      err40_1: 'Validation error: Invalid value format.',
      err40_link: 'Failed to load the file. Wrong URL',
      err41: 'Too large number',
      err42: 'Too small number',
      err43: 'Maximum character limit is exceeded',
      err44: '1 or more characters are required',
      err45: 'Mandatory field. Please enter data',
      err46: 'Invalid value format',
      err47: 'Invalid string format',
      err47_container: 'Error. Container number is wrong',
      err48: 'Data is not saved, validation error.',
      err49: 'Unknown enumeration variant',
      err50:
        'Data validation error. The file does not meet the download requirements',
      err90: 'Error: Element not found',
      err94:
        'Data error. Service is temporarily unavailable. Please try again later',
      err100: 'Invalid token',
      err101: 'Signature verification error',
      err102: 'Token expired',
      err103: 'Invalid token data (no parameters)',
      err104: 'Invalid token type',
      err105: 'Token has been changed or is invalid',
      err109: 'Invalid token type',
      err121: 'Failed to create token',
      err154_title: 'Something went wrong',
      err154_desc: 'The period is too long. Request less than 30 days',
      err201: 'User with this email is blocked. Please contact system admin',
      err202:
        'User with this email is not activated. Please contact system admin',
      err203: 'Signup is not available',
      err218: 'Passwords do not match',
      err219: 'User with this email already exists',
      err220: 'Invalid email format',
      err221: 'Invalid email or password',
      err223: 'Validation error: Invalid domain or URL format.',
      err223_https: 'Validation error: Invalid link format. Use https: //..',
      err224: 'Validation error: Invalid audience ID format.',
      err225: 'Validation error: Wrong target value format.',
      err227: 'Wrong old password',
      err400: 'Database Error',
      err900: 'Statistics is temporarily unavailable',
      err2010: 'Archive extract error',
      err2011: 'File copying error',
      err2020: 'Error reading directory. Impossible to receive the content',
      err2021: 'Receiving data error. Html file not found',
      err2030: 'Invalid file content',
      err2031: 'Invalid file format',
      err2032: 'File size exceeds',
      err2032_200: 'The file size exceeds 200 Mb.',
      err2033: 'Does not file',
      err2034: 'Wrong format',
      err2035: 'Invalid image width or height amount',
      err2026: 'The file was only partially uploaded',
      err2040: 'Invalid remote file URI',
      err2041: 'Failed to upload file by the link',
      err2050: 'Code must contain {LINK_UNESC} macro',
      err2051: 'Any link must contain {LINK_UNESC} macro',
      err2052: 'Error reading metadata. Invalid format of size meta-tag.',
      err2053: 'Failed to upload metadata. Meta tag size not found',
      err2054: 'Failed to upload metadata. Wrong file size',
      err2060: 'Invalid video duration',
      err2061: 'Invalid video resolution',
      err2062: 'Invalid video codec type',
      err2063: 'Invalid audio codec type',
      errAll: 'Oops, something went wrong',
      err_none: 'Please try to upload another file\n',
      err_valid: 'Validation error: ',
      err_access: {
        owner: 'Access denied. Please contact your administrator ',
        organization: 'Access denied. Organization is archived',
        agency: 'Access denied. Agency is archived ',
        client: 'Access denied. Client is archived',
        campaign: 'Access denied. Campaign is archived',
      },
    },
    errors_desc: {
      hours_of_day: {
        t1: '"By time of the day · UTC +3" target has been already added',
        t2: `"By time of the day · user's UTC" target has been already added to the current campaign.`,
        t3: 'Disable target “By time of the day · UTC +3” in the following creatives: ',
      },
      hours_of_day_user: {
        t1: `"By time of the day · user's UTC" target has been already added`,
        t2: `Таргет ""By time of the day · user's UTC" target has been already added to the current campaign.`,
        t3: 'Disable target “By time of the day · user’s UTC” in the following creatives: ',
      },
      days_of_week: {
        t1: '"By days of the week · UTC +3" target has been already added.',
        t2: '"By days of the week · UTC +3" target has been already added to the current campaign.',
        t3: 'Disable target “By days of the week · UTC +3” in the following creatives: ',
      },
      days_of_week_user: {
        t1: `"By days of the week · user's UTC" target has been already added.`,
        t2: `"By days of the week · user's UTC" target has been already added to the current campaign.`,
        t3: 'Disable target “By days of the week · user’s UTC” in the following creatives: ',
      },
    },
    upload: {
      error: 'Not all files have been uploaded',
      success: 'All files uploaded',
    },
    just_words: {
      w1: 'Campaign',
      w2: 'Creative · ',
      w3: 'Client',
      w4: 'Error',
      w5: 'Agency',
      w6: 'Campaigns',
      w7: 'User',
      Width: 'Width',
      Height: 'Height',
      confirm_action: 'Confirm',
      exit_page: 'Confirm navigation',
      exit_page_currect: 'Are you sure you want to leave the page?',
      exit_page_yes: 'Leave this page',
      exit_page_no: 'Stay on this page',
      one_duplicate: 'Duplicate',
      several_duplicates: 'Duplicate',
      one_is_successful_create: 'has been created successfully',
      several_successful_create: 'have been created successfully',
    },
    offers_page: {
      offer: 'Offer',
      offers: 'Offers',
      create_offer: 'Create offer',
      change_offer: 'Changing offer',
      add_in_archive: 'Are you sure you want to add the {{offer}} offer to the archive?',
      from_in_archive: 'Are you sure you want to extract the {{offer}}offer from the archive?',
      empty_offers: 'You have no offers',
      links_will_chaned: 'Links will be changed in <bold>{{creoCount}}</bold> creatives where the <bold>{{offer}}</bold> offer is used',
      links_chaned_1: 'Links have been changed in',
      links_chaned_2: 'creatives',
      some_time: 'This may take some time...',
      save_chanes: 'Do you want to save the changes made for the offer? This may take some time',
      back_to_list: 'Back to the offers list',
      use_offer: '<bold>{{currentOfferTitle}}</bold> offer is used in this campaign, editing offer\'s links is not allowed',
      enter_name: 'Enter name',
      archive: 'Archive',
      chanes_saved: 'Changes have been saved successfully',
      saving_chanes: 'Saving changes',
      select_cliient: 'Select client',
      creating_offer: 'Create offer',
      confirm: 'Confirm',
      somthing_wrong: 'Oops, something went wrong',
      search_with_name: 'Search by name',
      active_offer_none: 'There are no active offers',
      arhiv_offer_none: 'There are no offers in the archive',
      no_availibale_offers: 'There are no offers available',
      offer_archived: '<bold>{{title}}</bold> offer has been archived',
      offer_launched: '<bold>{{title}}</bold> offer extracted from archive',
      offer_has_created: '<bold>{{title}}</bold> offer created',
    },
    common: {
      savedBlock_part1: 'Block ',
      savedBlock_part2: ' saved successfully',
      targeting_key: {
        hour: 'Act (h)',
      },
      target_items: {
        ru: 'Russia',
        ua: 'Ukraine',
        rs: 'Serbia',
        lv: 'Latvia',
        kz: 'Kazakhstan',
        by: 'Belarus',
        kg: 'Kyrgyzstan',
        uz: 'Uzbekistan',
        de: 'Germany',
        gb: 'United Kingdom (GB)',
        uk: 'England (UK)',
        pl: 'Poland',
        'n/a': 'OTHER',
        us: 'USA',
        6252001: 'USA (6252001)',
        ng: 'Nigeria',
        my: 'Malaysia',
        ge: 'Georgia',
        ae: 'UAE',
        tr: 'Turkey',
        ar: 'Argentina',
        vn: 'Vietnam',
        in: 'India',
        ph: 'Philippines',
        tj: 'Tadjikistan',
        br: 'Brazil',
        th: 'Thailand',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        0: 'Sunday',
      },
      targetings: {
        source: 'Sources',
        network: 'Network SSP',
        geo_id: 'Regions',
        geo_raw: 'GEO +',
        city: 'Geo Regions & cities',
        os: 'Operating systems',
        video_type: 'Video format',
        browser: 'Browsers',
        browser_lang: 'Browser interface language',
        traffic_type: 'Traffic type',
        au: 'Audiences',
        au_bin_8: 'bin_au: Aidata (8)',
        au_bin_15: 'bin_au: UID 2.0 (15)',
        au_bin_20: 'bin_au: Client user audiences (20)',
        isp: 'Internet providers',
        app: 'Applications',
        private_deals: 'Direct deals',
        viewability: 'Viewability',
        uniq_show_creative: 'User frequency cap (creative)',
        uniq_show_creative_hour: 'Hourly frequency cap (creative)',
        uniq_show_creative_day: 'Daily frequency cap (creative)',
        days_of_week: 'Week days UTC+3',
        days_of_week_user: 'Week days (user timezone)',
        hours_of_day: 'By the time of the day UTC +3',
        hours_of_day_user: 'By the time of the day according to user’s UTC',
        site_domain: 'Domains',
        site_uri: 'URL',
        adgroup: 'AdGroup Google Presets',
        page_context: 'Context (websites)',
        socdem: 'Social demographic targeting',
        au_bin_info: 'Audiences',
        au_bin_lada: 'Audiences',
        uroven_dohoda: 'Income level',
        actions: 'Video ad event statistics',
        actions_domain: 'Domains activities',
        au_bin_21: 'Website audience by SSP uid (21)',
        semeinoe_polozhenie: 'Marital status',
        interesy: 'Interests',
        clean_all_targeting_creative: 'All the targets of creative',
        clean_all_targeting_campaign: 'All the targets of campaign',
        clean_all_targeting_success: 'have been cleared',
        clean_target_success: 'has been cleared',
        uniq_show_camp: 'User frequency cap (campaign)',
        uniq_show_camp_hour: 'Hourly frequency cap (campaign)',
        uniq_show_camp_day: 'Daily frequency cap (campaign)',
        keywords: 'Keywords and phrases',
        video_size: 'Video dimensions',
        sex: 'Sex',
        age: 'Age',
        sex_rtb: 'Sex RTB',
        categories_rtb: 'Interests RTB',
        age_rtb: 'Age RTB',
        adn: 'Ad networks',
        publisher: 'Publishers',
        os_versions: 'By OS version',
        spotid: 'Block ID',
        rewarded: 'Advertising for reward',
        video_size_site_domain: 'Video size grouped by domains',
        fullscreen: 'Fullscreen',
        exact_url: 'Target by full URL match',
      },
      pretargeting: {
        comp_retarget_enable_now: 'Campaign pretargeting settings are enabled',
        comp_retarget_enable: 'Enable campaign pretargeting settings',
      },
      targetings_error: {
        site_domain: {
          countModal: 'The number of domains exceeds 1000, use Whitelist in pretargets',
          count: 'The number of domains exceeds 1000, use Whitelist in pretargets',
          help: 'You can add max 1000 domains. To set up targets with more URLs, use Whitelist in pretargets.',
        },
        site_uri: {
          countModal: 'The number of URLs exceeds 100, use Whitelist in pretargets',
          count: 'The number of URLs exceeds 100, use Whitelist in pretargets',
          help: 'You can add max 100 URLs. To set up targets with more URLs, use Whitelist in pretargets.',
        },
        app: {
          resource_not_found: 'Resource with requested id was not found',
        },
      },
      tags: {
        revert_change: 'Revert change',
        second: 's',
      },
      reports: {
        day: 'General report',
        day_techfee: 'General report. Grouped by tech fee',
        yandex: 'Yandex.Metrika. Consolidated report',
        'nmi-integration-src': 'NMI report',
        integration: 'Integrated report',
        socdem: 'Socio-demographic report',
        geo_city: 'Geo report',
        shownum: 'Frequency report',
        extended: 'Extended report',
        none: 'You have no reports',
        no_data: 'No data for report available',
        no_reports: 'No reports available',
        general: {
          t1: 'Imp',
          t2: 'Date',
          t3: 'Clicks',
          t4: 'Spend',
          t5: 'Spend: ',
          t6: 'City',
          t7: 'Impression frequence',
          t8: 'Others',
          t9: '',
          t10: '',
          t11: '',
          t12: '',
          t13: '',
          t14: '',
          t15: '',
          actions: 'Actions',
        },
      },
      modal: {
        copy_list: 'Copy list',
      },
      empty_data: 'No data available',
    },
    charts: {
      impression: 'Impression',
      click: 'Click',
      view: 'Shows',
      spent: 'Spent',
      total: 'Total',
      utm_tags: 'UTM parameters',
    },
    statuses: {
      launched: 'Active',
      stopped: 'Stopped',
    },
    form: {
      common: {
        authorization: 'Authorization',
        login: 'Login',
        register: 'Sign up',
        signin: 'Log in',
        signup: 'Sign up',
        forgot: 'Forgot your password?',
        email: 'Email',
        password: 'Password',
        have_acc: 'Already have an account?',
        new_user: 'New user?',
        confirm: 'Confirm password',
        login_lab: 'Login',
        mob_phone: 'Mobile phone',
        skype: 'Skype',
        telegram: 'Telegram',
        wrongPassword: 'Wrong password',
        passesMiss: 'Passwords do not match',
        passMiss: 'Password does not match',
        new_password: 'New password',
        saveChanges: 'Save changes',
        enter_email: 'Enter email address',
        wrong_format: 'Invalid email format',
        phone: 'Phone',
        login_rules: 'Login must be between 3 and 100 characters',
        phone_rules: 'Phone must be between 3 and 100 characters',
        required: 'Required field',
        password_rules: 'Password must be more than 6 characters',
        password_long: 'Password is too long',
        password_short: 'Password is too short',
        email_exist: 'Such an email already exists',
        login_long: 'Login is too long',
        login_short: 'Login is too short',
        field_long: 'Field is too long',
        field_short: 'Field is too short',
        offer: 'By signing up you agree to the ',
        offer__link: 'Terms of the Offer',
        offer__link__auth: 'EULA',
        not_valid_link: 'This link is not valid',
        fill_the_field: 'Fill in the field',
      },
    },
    top: {
      partner_managed: 'managed by partner',
      partner_organization: 'managed by agency',
      exit: 'Log out',
      menu: {
        clients: 'Clients',
        campaigns: 'Сampaigns',
        containers: 'Containers',
        load_data: 'Upload data',
        account: 'Account',
        bwlists: 'Black & White lists',
        agency: 'Agencies',
        partners: 'Partners',
        users: 'Users',
        uploadbwl: 'Load BWLists',
        history_list: 'History of changes',
        control_panel: 'Dashboard',
        project: 'Project',
      },
    },
    balance_modal: {
      from: 'From',
      to: 'To',
      enter_am: 'Enter the amount to transfer',
      transfer: 'Transfer',
      not_enough: 'Not enough funds for transaction',
      success_1: '{{amount}} {{currency}} were successfully transferred to {{label}}',
      select_client: 'Select a debit account',
      select_count: 'Select a deposit account',
    },
    bwlist_modal: {
      title: 'Upload BWLists',
      dropzone_text:
        'Select or drag and drop files for black lists with allowed names.If a file with the same name already exists, it will be replaced.',
      button_ok: 'OK',
    },
    blackLists: {
      title: 'DMP Black Lists',
      file_type: 'file type',
      global: 'Global',
      clients: 'Client',
      exported: 'Exported',
      publisher: 'Publisher',
      who_deleted: 'Deleted by',
      selected_string: '<bold>{{value}}</bold> rows selected',
      del_from_bl: 'Delete from the Black List',
      add_from_bl: 'Add to the Black List',
      del_from_bl_real: 'Are you sure you want to delete the selected rows from the Black List file?',
      add_from_bl_real: 'Are you sure you want to add the selected rows to the Black List file?',
      source: 'Source',
      domain: 'Domain',
      adding_to_bl: 'Adding to the Black List',
      deleting_from_bl: 'Deleting from the Black List',
      date_del: 'Date the entry was deleted',
      date_add: 'Date the entry was added',
      on_in_bl: 'Blacklisted',
      off_from_bl: 'Removed from the Black List',
      something_wrong: 'Something went wrong. The file is not updating',
    },
    multiple_upload: {
      status: {
        all: 'All files have been loaded',
        not_all: 'Not all files have been loaded',
      },
    },
    login_page: {
      error_email: 'Invalid email address',
      error_password: 'Invalid email or password',
      language_tit: 'Language',
      user_blocked:
        'User with this email is blocked. Please contact system admin',
      user_noact:
        'User with this email is not activated. Please contact system admin',
      registration_unavailable: 'Registration is not available',
      mail_already_registered: 'User with this email already exists',
      confirm_pass: 'Passwords do not match',
      unknown_error: 'Unknown error',
      user_is_not_active:
        'User with this email is not activated. Please contact system admin',
      hasEmail: 'User with such email already exists',
      weSendInstructions: 'We have sent you an email with instructions',
      successfullyVerified: 'Email address has been confirmed successfully',
      passChange: 'Password has been changed successfully',
      forgotPass: 'Forgot password?',
      forgotYourPass: 'Forgot your password?',
      resetPass: 'Reset Password',
      changeYourPass: 'Change your password',
    },
    clients_page: {
      clients_tit: 'Clients',
      add_client: 'Add client',
      client_name: 'Name',
      clients_search: 'Search',
      clients_all: 'All Сlients',
      clients_stopped: 'Paused',
      clients_deleted: 'Deleted',
      active_clients: 'Running',
      archive_clients: 'Archive',
      edit_icon: 'Edit',
      add_arc_icon: 'Archive',
      rem_arc_icon: 'Extract from archive',
      report_client_name: 'Client’s name',
      report_client_id: 'Client’s id',
      client_report: 'Client\'s report',
      client_archive_from_text:
        'Are you sure you want to extract the client from the archive ',
      client_archive_to_text:
        'Are you sure you want to add the client to the archive ',
      client_archive_from_alert: ' extracted from the Archive',
      client_archive_to_alert: ' added to the Archive',
      show_columns: 'Show stats columns in the campaign list',
      nav_menu: {
        basic_settings: 'Basic Settings',
        reports: 'Reports',
      },
      client_create: {
        hint1: 'Mandatory field',
        hint2: 'Failed to load the file. File size exceeds 1 Mb',
        hint3: 'Failed to load the file. Image size exceeds 2000x2000 px',
        hint4: 'Failed to load the file. Wrong format',
        hint5: 'Failed to load the file. Wrong URL',
        hint6: 'Client has been successfully ',
        hint7: 'saved',
        hint8: 'added',
        hint9: 'Failed to load the file.',
        hint10: 'File size is more than 1 Mb',
        hint11: 'File not found',
        hint12: 'Invalid file type',
        hint13: 'An error has occurred. Unable to save changes',
        client_create_tit: 'Add client',
        client_load_img: 'Upload image',
        client_img_link: 'Link to image',
        client_img_formats: 'Allowed formats: png, jpeg, jpg, svg.',
        client_img_size: 'Max size: 1 Mb',
        client_img_maxsize: 'Max resolution: 2000×2000',
        client_name: 'Name *',
        client_site: 'Website',
        client_reports: 'Reports',
        client_use_systemset: 'Use system settings',
        client_report_list:
          'The list of available reports can be changed after adding a client ',
        client_agency: 'Agency',
        client_default_title: 'New client',
      },
      client_edit: {
        client_edit_tit: 'Edit client',
        client_edit_delete_avatar: 'Delete',
        client_edit_reports: 'Reports',
        client_edit_use_settings: 'Use system settings',
        day: 'General report',
        tech_fee: 'General report. Grouped by tech fee',
        metrika: 'Yandex.Metrika. Consolidated report',
        consolidated_report: 'Consolidated report',
        nmi_report: 'NMI report',
        integr_report: 'Integrated report',
        soc_dem_report: 'Socio-demographic report',
        geo_report: 'Geo report',
        frequency_shows: 'Frequency report',
        extended_report: 'Extended report',
        included: 'Enabled',
        turned_off: 'Disabled',
        available: 'Available',
        moderation_creatives: 'Creative moderation',
        update_hand: 'Approve creatives manually',
        update_auto: 'Approve creatives automatically',
        categories_creatives: 'Creative categories',
        switch_points:
          'Enabled creatives will be displayed in the Category tab',
        animation: 'Animation',
        jest: 'Severity',
        erotic: 'Erotic',
        link_to_image: 'Link to image',
        link_to_image_link: 'Link',
        link_to_image_add: 'Save',
        link_to_image_cancel: 'Cancel',
      },
      clients_none: {
        empty1: 'You have no active clients',
        empty2: 'You have no stopped clients',
        empty3: 'You have no clients in the archive',
        empty4: 'You have no remote clients',
        empty5: "You don't have clients yet.",
        empty6: 'Click to create a new one.',
      },
      limit_groups: {
        title: 'Limit groups',
        title2: 'Limit group',
        group: '',
        created: 'group has been created successfully',
        changed: 'group has been changed successfully',
        add_new: 'Add a new group',
        no_settings: 'For this client limit groups are not set',
        clear_group: 'Clear a group',
        search_camp: 'Company Search',
        show_groups: 'Show groups (limits)',
        show_groups_hints: 'Group limit settings in campaigns and creatives',
        cancel_del: 'Cancel deletion',
      },
    },
    campaigns_page: {
      camp_hint: {
        hint1: 'Data is not saved, validation error',
        hint2: '',
        hint3: '',
        hint4: '',
        hint5: '',
      },
      coef_name: 'Algorithm name',
      choose_action: 'Choose an action from field',
      icons: 'Icons',
      popular: 'Popular',
      campaigns: 'Сampaigns',
      camp_search: 'Search in campaigns',
      show_full_name: 'Campaign full name',
      show_short_name: 'Campaign short name',
      create_btn: 'Create campaign',
      campaign_additional: {
        special: {
          title: 'Special settings',
          lifetime_label: 'Click lifetime',
          lifetime_help: 'Click lifetime is set in days, max - 60 days',
        }
      },
      experimental: {
        by_default: 'Set value by default',
      },
      campaign_category: {
        "Video": "Video",
        "MS_performance_audience": "MS performance audience",
        "MS_performance_context_audience": "MS performance context audience",
        "Performance_audience_pbd": "Performance audience of the platform of big data",
        "Performance_audience_outside_DMP": "Performance audience of third party DMP",
        "Reach_WL": "Reach White list",
        "Reach_Premium": "Reach Premium",
        "Reach_Middle": "Reach Middle",
        "Reach_Budget": "Reach Budget",
        "inn_app": "In-App",
      },
      campaigns_black_header_main: {
        act: 'Running',
        pause: 'Paused',
        del: 'Deleted',
        arch: 'Archival',
        all: 'All Campaigns',
      },
      campaigns_comments: {
        title: 'Comments',
        add_comment_btn: 'Add a comment',
        add_comment_tit: 'Add a comment',
        del_comment: 'Deleting a comment',
        edit_comment: 'Edit comment',
        comment_deleted: 'Comment has been deleted',
        confirm_del: 'Are you sure you want to delete this comment',
        show_comments_part: 'Show all comments',
        hide_comments: 'Hide comments',
        roll_up: 'Collapse',
        expand: 'Expand',
        saved: 'Saved',
        save_error: 'Save error. Repeat in a minute.',
        no_search: 'Nothing found',
        search_placeholder: 'Enter a comment or e-mail',
        symbols: 'characters',
        empty: 'No comments',
      },
      campaigns_header_main: {
        total: 'General statistics',
        name: 'Name',
        show: 'Imp',
        click: 'Clicks',
        spent: 'Spend',
        budget: 'Budget',
        status: 'Status',
        cpc: 'CPC',
        cpm: 'CPM',
        ctr: 'CTR',
      },
      campaigns_status: {
        act: 'Running',
        pause: 'Paused',
        del: 'Deleted',
      },
      campaigns_copy_modal: {
        title: 'Copy campaign',
        de_start_predix_one: '',
        de_start_predix_many: '',
        mass_copy_success_one: 'has been successfully copied',
        mass_copy_success_many: 'have been successfully copied',
        mass_copy_in_one_client: 'to the client',
        mass_copy_in_many_clients: 'to the clients',
        unknow_error: 'Unknown copy error',
        error_copy: 'Copy error',
        limit_copy: 'Copying is restricted due to a conflict in targets',
        select_comp: 'Select the clients to copy the campaigns to',
      },
      campaign_stat_icon: 'Statistics',
      campaign_del_icon: 'Delete',
      campaign_stat_modal: {
        tit: 'Statistics',
        shows: 'Shows',
        clicks: 'Clicks',
        spent: 'Spend',
        budget: 'Budget',
      },
      chart: {
        show: 'Show the graph',
        hide: 'Hide the graph',
        no_stats: 'No statistics are available',
      },
      empty: {
        act: 'You have no active campaigns',
        no1: 'You have no campaigns yet.',
        no2: 'Click to add a new one.',
        no3: 'You haven’t paused any campaign',
        no4: 'You haven’t deleted any campaign',
        no5: 'You have no campaigns in the archive',
      },
      create_campaign: {
        camp_create_hint: 'Campaign has been created successfully ',
        black_header: {
          back: 'Back',
          set: 'Settings',
          targ: 'Targetings',
        },
        newcamp_tit: 'New Campaign',
        req_fill: 'Mandatory field',
        tit: 'Creating campaign',
        client: 'Client',
        adv_name: 'Advertiser',
        name: 'Name *',
        categories_campaign: 'Campaign categories',
        status: 'Status',
        period: 'Period',
        data_start: 'Start date',
        date_stop: 'End date',
        budget_tit: 'Budget',
        budget_text:
          'Please note, when the funds are spent, the campaign will be stopped. Budget can be increased at any time.',
        budget: 'Budget',
        price: 'Costs',
        enter: 'Please enter…',
        without_limit: 'Unlimited',
        limits: 'Limits',
        clicks: 'Clicks',
        shows: 'Shows',
        shows_one: 'Shows per user',
        limits_budget: 'Budget',
        hour: 'Hour',
        day: 'Day',
        hour_rub: 'Hour',
        day_rub: 'Day',
        all: 'Total',
        create_btn: 'Create',
        cancel_btn: 'Cancel',
        ok_btn: 'ОК',
        create_text:
          'Create new ad campaigns using our Trading Desk platform. We offer a wide range of various actions available for all campaigns. You can always monitor their status, as well as regularly view statistics and detailed reports.',
        create_text2:
          ' If you have any questions or need additional information, please read TD',
        create_text_link: ' documentation',
      },
      pretarg_text: 'To set targets, please create the campaign',
      targeting_word: 'Targeting',
      campaign_settings: {
        date_hint: {
          h1: 'Campaign end date must be after start date',
          h2: 'Date format must be DD/MM/YYYY',
          h3: '',
        },
        campaign_prompt: {
          main: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B0%D1%8F-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D1%8F',
          },
          period: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4',
          },
          moreOptimisation: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B0%D1%81%D1%88%D0%B8%D1%80%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F-%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
          limits: {
            link: 'https://terratraf.readme.io/docs/campaigns-limits',
          },
          container: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%B9%D0%BD%D0%B5%D1%80',
          },
          optimization: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
        },
        hint_for_card: {
          optim_part1: 'To start ad running, ',
          optim_part2: 'fill in the Optimization section.',
          limit_part1: 'Set limits ',
          limit_part2: 'for budget and other options.',
        },
        camp_name_fied: {
          name1: '"Advertising campaign"',
          name2: '"Period"',
          name3: '"Optimization"',
          name4: '"Limits"',
        },
        on_edit: {
          hint1: 'Section ',
          hint2: ' has been saved successfully',
          hint3: 'Section has been saved successfully',
        },
        enter_data_tip: 'Enter data',
        click_hint: {
          hint1: '[dd/mm/yyyy]',
          hint2: 'space',
          hint3: '[value]',
          hint4: '[parameter]',
          hint5: 'e.g.: 18.12.2019 300 1.4',
          hint6: 'e.g.: 18.12.2019 1.4',
        },
        creative_hint: 'You can target the whole ',
        creative_hint2: 'campaign ',
        creative_hint3: 'or each creative separately',
        nostat: 'Statistics not available',
        black_menu: {
          Main: 'Main',
          Back: 'Back',
          Main_settings: 'Main settings',
          Targeting: 'Targeting',
          Creatives: 'Creatives',
          Reports: 'Reports',
          Settings: 'Settings',
          Settings_aditional: 'Additional settings',
        },
        ListSRMethods: {
          enter_data: 'Enter data',
          label: 'Method of collecting statistics',
          CAMPAIGN_TYPE: 'by campaign type',
          COEFFICIENTS: 'by coefficients',
          DSP_CALCULATION: 'by client expense from DSP',
          TD_CALCULATION: "by client's expenses from TD",
          RAW_STAT: 'by RAW STAT',
          MAX_FIX_CPC_MARGINALITY: 'maximum cost per click',
          max_cpc: {
            label: 'Max CPC',
            hint: 'Max cost per click for method: MAX_FIX_CPC_MARGINALITY',
          },
          marginality: {
            label: 'Marginality, %',
            hint: 'Marginality for CPC recosting method: MAX_FIX_CPC_MARGINALITY, should be set as a percentage',
          },
          hidden_coef: {
            label: 'Hidden fee, %',
          },
        },
        Client: 'Client',
        Advertiser_name: 'Advertiser',
        Campaign_name: 'Advertising campaign',
        tags_label: 'Tags',
        tags_placeholder: 'Enter data',
        Running: 'Running',
        Paused: 'Paused',
        Edit: 'Edit',
        Period: 'Period',
        Start_date: 'Start date',
        End_date: 'End date ',
        Optimization: 'Optimization',
        moreOptimisation: {
          title: 'Extended optimization',
          algoritm: 'Optimization algorithm',
        },
        Container: 'Container',
        container_block: {
          cid: 'Container id',
          save: 'Save click id (strk) in cookie when redirecting',
          additionally: 'Additionally',
        },
        Budget: 'Budget',
        Budget_RAW: 'Budget RAW',
        Please_note:
          'Please note, when the funds are spent, the campaign will be stopped. Budget can be increased at any time.',
        cost: 'Costs',
        Please_enter: 'Please enter…',
        Limit_display_Adriver: 'Set Adriver frequency cap',
        Unlimited: 'Unlimited',
        Limits: 'Limits',
        Clicks: 'Clicks',
        Impressions: 'Impressions (total)',
        per_user: 'Impressions (user)',
        Hour: 'Hour',
        Day: 'Day',
        Total: 'Total',
        Total_raw: 'Budget',
        additional: {
          set_tit: 'Settings',
          coefficient: 'Odds',
          method: 'Agency spendings calculation method',
          range: 'RANGE CALCULATION COEFFICIENT',
          info_1: 'Range calculation coefficients are applied to campaigns that use "By campaign type" statistics calculation method',
          info_2: 'If you want the "By campaign type" statistics calculation method to be automatically applied to campaigns in the TD admin, enable <bold>lable - {{method}}</bold> in agency',
          price: 'Price',
          type: 'Type',
          clicks: 'Clicks',
          from: 'From',
          to: 'To',
          maxToBeMax: 'The maximum number of the range must be greater than the minimum',
          hide_real_statistics: {
            title: 'Hide real statistics',
            hintStrong: 'Campaign statistics is hidden!',
            hintSpan: 'To display statistics, uncheck the box',
          },
          aggregation: 'Campaign agregation',
          ddmm: 'Select...',
          eg: 'e.g.:',
          ext_sour: 'External sources',
          ids: 'IDs',
          id_from_ss: 'Enter ID from SS',
          dashboard_ids: 'Dashboard',
          reports: 'Reports',
          use_client: 'Use client settings',
          enabled: 'Enabled',
          extend_rep: 'Extended report',
          data: 'Data',
          enter_date: 'Enter data',
          data_based: 'Data is based on General report',
          use_special: 'Use special character  “ | “as column delimiter',
          help_text:
            'Users with admin privileges have access to a wide range of settings which allow to set daily price params (for the CPA price type), link external sources, manage the list of available reports etc.',
          pre_targ_tit: 'Pretargeting',
          pre_targ_main: 'General settings',
          pre_targ_search: 'Search',
          pre_targ_brand_on: 'On',
          pre_targ_brand_off: 'Off',
          pre_targ_brand_rot: 'Running',
          pre_targ_brand_rotall: 'Run ads on all websites',
          pre_targ_brand_rotrus:
            'Run ads on websites containing a text in Russian',
          pre_targ_brand_rotcat: 'Exclude from ad running',
          pre_targ_categories: {
            drugs_n_diseases: 'Drugs and diseases ',
            indecency: 'Obscenity',
            media_test: 'Pharmaceuticals',
            obscenity_br: 'Russian mat (word roots)',
            obscenity_r: 'Russian mat (extended)',
            slang: 'Slang',
            swearing_en: 'English mat',
            swearing_ru: 'Russian mat (most common)',
            tragedy: 'Disasters',
            ujc: 'User generated content',
          },
          statistics: {
            title: 'Statistics',
            text: 'Show statistics columns in the campaign',
            select_all: 'Select all',
            selected: 'selected',
            of: 'from',
            save_empty_message:
              'You have not selected any option. After saving, the default settings are displayed',
            exit_message: 'Are you sure you want to leave this page?',
            show_columns: 'Show stats columns in the creative list',
          },
          links: {
            copy: 'Disapprove IDs have been successfully copied to the clipboard',
            title: 'Links',
            tooltip: 'Copy disapprove IDS',
            tabs: {
              links: 'Links',
              google: 'Google',
              yandex: 'Yandex',
            },
            table: {
              link: 'Navigation link',
              pixel: 'Pixel audit',
              pixel_additional: 'Optional pixel audit:',
              status: 'Status',
            },
          },
        },
        reports: {
          reports_tit: 'Reports',
          back: 'Back',
          donwload: 'Download',
          see: 'View',
          nodata: 'No data available for this period',
          gen_report: 'General report',
          targ_report: 'Targeting report',
          shows_report: 'Frequency report',
          geo_report: 'Geo report',
          socdem_report: 'Socio-demographic report',
          integr_report: 'Integrated report',
          yandex_report: 'Yandex.Metrika. Consolidated report',
          fee_report: 'General report. Grouped by tech fee',
          hide_fromchart: 'Hide it on the chart',
          show_fromchart: 'Display on the chart',
          stat_tit: 'Statistics',
          sort_high: 'Sort ascending',
          sort_low: 'Sort descending',
          sort_cancel: 'Remove sorting',
        },
        push_notifications: {
          title: 'Push Notifications',
          hint: 'Get Push Notifications for campaign changes',
          hint2: 'Get PUSH notifications about creative change',
          browser_push_error_1:
            'Notifications have been disabled on your website.',
          browser_push_error_2: 'Allow notifications in your browser settings.',
        },
      },
      stat_chart: {
        tit: 'Statistics',
        shows: 'Imp',
        clicks: 'Clicks',
      },
      camp_del_tit: 'Delete campaign',
      camp_is_deleted: 'deleted',
      camp_del_quest: 'Are you sure you want to delete it',
      camp_archive_from_text:
        'Are you sure you want to extract campaign from the archive ',
      camp_archive_to_text:
        'Are you sure you want to add campaign to the archive ',
      camp_archive_from_alert: ' has been extracted from the archive',
      camp_archive_to_alert: ' has been added to the archive',
      copy_campaign: {
        t1: 'Campaign target settings ',
        t1_2: 'Campaign target and pretarget settings ',
        t2: ' have been copied to campaigns',
        t3: 'Select campaigns where it is needed to copy target',
      },
      mass_copy: 'Copy to another client',
      mass_actions_success: 'Campaigns have been',
      mass_actions_success_eng: 'successfully',
      bulk_action_alert: 'Are you sure you want to',
      client_company_alert: "client's campaigns",
      client_company_alert_for_del: 'campaigns of client',
      client_company_alert_for_double:
        'Are you sure you want to duplicate the campaigns of the client',
      client_company_expect_to: "extract client's campaigns from the archive",
    },
    users_activity: {
      users: 'Users',
      client: 'Client',
      organisation: 'Organisation',
      agency: 'Agency',
    },
    users_api: {
      create_title: 'Creating an API user',
      info_1: 'Creating technical users enables providing access to API2 with a specific set of methods. Used for operations with TD from external systems.',
      select_partners: 'Select partners you will have access to',
      set_groups: 'Assign groups to the user. Just enable Default',
      use_tegs: 'Use tags, if you need to get stats on specific campaigns. Please note, tags must be added to the campaign settings with the same name',
      methods_list: 'Method list allows you to keep control over access to the information',
      see_methods_info: 'See method description',
      methods: 'Methods',
      token: 'Token',
      passwordHelp: 'Password must contain more than 3 characters',
    },
    users_page: {
      page_title: 'Users',
      search_placeholder: 'Search by login',
      create_btn: 'Create a user',
      users_api: 'API users',
      update_token: 'Update token',
      token_has_update: 'Token has been updated successfully',
      token: 'Token',
      userDel: 'User',
      userHBD: 'has been deleted',
      navigation: {
        allUsers: 'All users',
        online: 'Online',
        statistics: 'Statistics',
      },
      table: {
        name: 'Name',
        type: 'Type',
        login: 'Login',
        groups: 'Groups',
        status: 'Status',
        delete_user: 'Delete',
        edit_user: 'Edit',
        active_time: 'Activity time',
        count_pages: 'Pages',
        count_get: 'Views',
        count_edit: 'Actions',
        hinits: {
          active_time:
            'The time of user activity. User becomes inactive after 5 minutes of inactivity',
          count_pages: 'The number of all loaded pages',
          count_get:
            'The number of requests to view the settings of campaigns, creatives and target statistics.',
          count_edit:
            'The number of requests to add or change general campaign or creative settings as well as additional settings: tags, comments, targeting',
        },
      },
      statuses: {
        active: 'Active',
        blocked: 'Blocked',
      },
      delete_modal: {
        title: 'Delete user',
        text: 'Are you sure you want to delete the user',
      },
      settings_modal: {
        create_title: 'Create a user',
        edit_title: 'Edit a user',
        complete_registration: 'Complete registration',
        registration_done: 'Registration has been completed',
        email: 'E-mail address',
        roles_title: 'Roles',
        parthner_title: 'Partner',
        select_roles: 'Select roles',
        select_parthner: 'Select a partner',
      },
    },
    partners_page: {
      page_title: 'Partners',
      search_placeholder: 'Search id | xxhash | Name',
      table: {
        name: 'Name',
        type: 'Type',
        login: 'Login',
        balance: 'Balance',
        delete: 'Delete',
        edit: 'Edit',
        enable_registration: 'Registration is enabled. Deny?',
        deny_registration: 'Registration denied. Allow?',
        change_registration: 'Registration changed',
      },
      statuses: {
        active: 'Active',
        blocked: 'Blocked',
      },
      delete_modal: {
        title: 'Deleting',
        text: 'Are you sure you want to delete it ',
      },
    },
    agencies_page: {
      agency_title: 'Agencies',
      agency_new: 'New agency',
      create_btn: 'Create agency',
      search_placeholder_agency: 'Search by agency',
      agencies_active: 'Running',
      agencies_all: 'All Agencies',
      agencies_report: 'Agency\'s report',
      show_audiences: 'Show audiences',
      agency_none: {
        empty1: "You haven't any active agencies",
        empty2: "You haven't any stopped agencies",
        empty3: "You haven't any agencies in the archive",
        empty4: "You haven't any deleted agencies",
        empty5: "You haven't any agencies yet. ",
        empty6: 'Click to create',
      },
      archive_modal: {
        title_to: 'Add to archive',
        title_from: 'Extract the from archive',
        btn_to: 'To the archive',
        btn_from: 'Extract',
        text_to1: 'Are you sure you want to add the',
        text_to2: 'agency to the archive',
        text_from1: 'Are you sure you want to extract the',
        text_from2: 'agency from the archive',
        alert_to1: '',
        alert_to2: 'agency has been added to the archive',
        alert_from1: '',
        alert_from2: 'agency has been extracted from the archive',
      },
      modal: {
        title: 'Add agency',
        edit_title: 'Edit agency',
        name: 'Name',
        site: 'Website',
      },
      agency_create: {
        hint1: 'Agency has been successfully ',
        hint2: 'saved',
        hint3: 'added',
      },
      report: {
        title: 'Audience report',
        add_au: 'Add audience',
        titleml: 'ML audiences',
        reportAuMl: 'ML audience report',
        del_au: 'Deleting {{title}} audience',
        del_ask: 'Are you sure you want to delete <bold>{{title}}</bold> audience?',
        hint: 'The statistics of this campaign will be taken into account in the selected ML audience',
        hint_link: 'of the agent report',
        au_empty: 'You have no ML audiences yet.<br/> Add the first one.',
        success_del: 'Audience <bold>{{title}}</bold> has been deleted successfully',
        table_title: 'Name / Client’s ID',
      }
    },
    project_page: {
      page_title: 'Project',
      main_settings: 'Main settings',
      settings: 'Settings',
      show_columns: 'Show stats columns in the client list',
      table: {
        name: 'Name',
        code: 'Code',
        tech_domain: 'TECHNICAL DOMAIN',
        ext_domain: 'External Domain',
      },
      errors: {
        error1: 'error',
      },
      forms: {
        field: {
          required: 'Required',
          off: 'Off',
          optional: 'Optional',
        },
        project_title: 'Project',
        code: 'Code',
        title: 'Name',
        offer_eng: 'Offer (English version)',
        ext_domain: 'External Domain',
        currency: 'Currency',
        partner: 'Partner',
        branding_title: 'Branding',
        slogan: 'Slogan',
        logo_medium: 'Logo in authorization',
        logo_small: 'Logo in the header',
        logo_letters: 'Letter logo',
        background_letter: 'Letter background',
        background: 'Background',
        not_selected: 'Not selected',
        logotype: 'Project logo',
        background_email: 'Background for sending e-mail',
        logo_email: 'Logo e-mail',
        custom_link_title: 'Link to image',
        upload_hint:
          'Supported formats: png, jpeg, jpg, svg. The maximum size is 6 MB. ',
        registration_title: 'Registration',
        registration_partner_title: 'Partner',
        registration_partner_placeholder: 'Enter ID | xxhash | title',
        registration_partner_hint:
          'Select a partner to change registration settings',
        registration_allowed: 'User registration allowed.',
        registration_prohibited: 'User registration is prohibited.',
        registration_changed: 'You can change the setting',
        registrations_changed_link: 'in the Partners section',
        registration_form: 'Registration form',
        registration_rules: 'Available user roles',
        offerCustomLinkTitle: 'Document Link',
        offer_label: 'Offer',
        offer_hint: 'Allowed formats: pdf.',
      },
    },
    history_changes_widget: {
      no_changes: 'There are no changes',
      look_all_changes: 'See all changes',
    },
    history_changes_page: {
      title: 'History of changes',
      header_date: 'Date and time',
      header_who: 'Who changed',
      header_campaign: 'Campaign',
      header_creative: 'Creative',
      header_parametr: 'Change parameter',
      header_changes: 'What has changed',
      hide_detail: 'Hide changes',
      show_detail: 'Show changes',
      full_view: 'Full view',
      compact_view: 'Compact view',
      rows_limit: 'Rows limit',
      all_objects: 'All objects',
      all_actions: 'All actions',
      add: 'Add',
      edit: 'Edit',
      copy: 'Copy ',
      targets: 'Targets',
      campaign_start_date: 'Campaign start date',
      campaign_end_date: 'Campaign end date',
      objects: 'Objects',
      inter_id_hash: 'INTERNAL IDENTIFIER | XXHASH | NAME',
    },
    analysis_page: {
      title: 'Ad creatives analysis',
      filters_title: 'Filters',
      placeholper_partner: 'xxhash | title',
      select_list: 'Select from the list',
      help_au: 'Only digits allowed, more than one character a line',
      help_dir:
        'Value format: digits, symbols, English letters. More than one character a line, e.g.: BTW-34774-605',
      placeholder_textarea:
        'Enter the names of the target elements using ENTER',
      search: 'Search',
      header_table_nameCamp: 'Campaign name',
      header_table_idCamp: 'Ad campaign ID',
      header_table_nameCreo: 'Creative name',
      header_table_idCreo: 'Creative ID',
      au: 'Audience',
      dir: 'Direct deal',
      add_list: 'Add list',
      selected: 'selected',
      of: 'from',
      invalid_input_1:
        'Invalid input. Please make sure the values are specified with ENTER',
      invalid_input_2:
        'Invalid input. For Audience target, only digits can be used. Please make sure the values are specified with ENTER',
      au_shop: 'Shops\' audiences',
    },
    search_none: {
      none_1: 'No results were found for this request',
      none_2: 'Perhaps one or more words in the request are misspelled.',
      none_3: 'Please try again.',
    },
    filter_none: {
      none_1: 'There is no company that matches the given parameters.',
      none_2: 'Create a company or change filters.',
    },
    expand_role: {
      ex1: 'Decrease font',
      ex2: 'Increase font',
      ex3: 'Collapse',
      ex4: 'Copy',
    },
    date_pick: {
      days30: 'Last 30 days',
      month: 'This month',
      week: 'This week',
      day: 'Today',
      yesterday: 'Yesterday',
      all_time: 'For all time',
      select: 'Select period',
    },
    filter: {
      customize: 'Configure filters',
      and: 'AND',
      date__start: 'Start date',
      date__end: 'End date',
      format__date: 'dd/mm/yy',
    },
    profile: {
      profile_word: 'Profile',
      balance: 'Balance',
      balance_refill: 'PLEASE ENTER AMOUNT, ₽',
      refill: 'Top up',
      top_up_error:
        'An unexpected error occured, the funds have not been added. Please try to top up the balance later.',
      top_up_success: 'Top up has been successfull',
      partner_managed: 'managed by agency',
      ava_funds: 'Available funds',
      settings_tit: 'Settings',
      main: 'Main',
      app_notification_on: 'Allow information messages',
      app_notification_off: 'Do not allow information messages',
      push: {
        title: 'Push notification',
        toggle_active: 'Allow push notifications',
        toggle_inactive: 'Do not allow push notifications',
        test: 'Submit test',
        about: 'What to report',
        bw_lists: 'BWLists uploading',
        new_message: 'New messages',
        refill: 'Account refill',
        end_campaign: 'End Campaign',
        check_all: 'Check all',
        clear: 'Clear',
      },
      edit: {
        edit_save: 'Profile saved successfully',
      },
      view: {
        title: 'View',
        theme_subtitle: 'Default table view',
        noraml_view: 'Normal view',
        extended_view: 'Extended view',
        super_extended_view: 'Fullscreen view',
        toggle_animate: 'Animation',
        animate_info:
          'Disable interface animation to speed up the process of working with controls',
        hide_navigation_campaign: 'Hide campaign navigation',
        hide_navigation_creative: 'Hide creative navigation',
        navigation_info_comp:
          "Disable interface navigation if you don't need to navigate between campaigns",
        navigation_info_creo:
          "Disable interface navigation if you don't need to navigate between creatives",
        show_prompt_in_form: 'Show tooltips in form fields',
        show_prompt_in_form_info:
          'Please remember to fill in the important fields while creating or editing campaigns and creative',
        hide_hints: 'Hide hints',
        hide_hints_add: 'Hints referring to the Trading Desk documentation for setting up campaigns, creatives, targets etc.',
      },
      safety: {
        title: 'Security',
        subtitle: 'Change password',
        old_password: 'Enter current password',
        new_password: 'Enter new password',
        confirm_password: 'Confirm new password',
      }
    },
    alerts: {
      a1: 'Iframe code has been copied to clipboard',
      a2: 'HTML code has been copied to the clipboard',
      a3: 'successfully copied to clipboard',
      copied: 'Copied',
      view_mode: 'View mode.',
      view_mode_cont: 'You can not edit pages',
      a4: 'Preview code has been copied successfully',
    },
    targetings: {
      saveblock: {
        hint1: 'Section ',
        hint2: ' has been saved successfully',
        hint3: 'Section has been saved successfully',
      },
      hide: 'Ausblenden',
      uncover: 'Einblenden',
      show_statictics: 'Show statistics',
      show: 'Show · ',
      all: 'All',
      total: 'Total',
      custom: 'Customized',
      search: 'Search',
      use_set: 'Targeting settings of the ',
      use_set1: 'campaign are used',
      use_set2: 'creative are used',
      use_set_creo: 'Use own targeting settings for creative',
      use_set_modal_creo: 'Use campaign target settings after clearing target',
      stat_not_going: 'Statistics are not collected for this target',
      reset_target_settings: 'Are you sure you’d like to reset target settings in the campaign',
      stat_unavaiable: 'Target statistics is not available',
      add_locations: 'Add locations',
      add_list: 'Add list',
      update_guide: 'Update directory',
      down_stat: 'Download statistics',
      update_stat: 'Update statistics',
      update_au: 'Update audiences',
      have_camp_set_tit: 'Elements have campaign settings',
      have_camp_set:
        'Enable “Use your own creative target settings” to be able to edit elements. If you are going to use the campaign settings again in the future, deactivate your settings',
      no_list: {
        general: 'You have not added any catalogue items yet',
        city: 'You have no locations in the list. Click to add ',
      },
      noselected: 'Show on selected elements',
      selected: 'Show everywhere except selected elements',
      noselectedprompt:
        'Select target elements if you`d like to run ads on them',
      selectedprompt:
        'Use it if you`d like to exclude running ads on this elements. To do it, select them in the list',
      no_data: 'No data available for this period',
      statistic_off: 'Statistics disabled',
      no_statistic: 'Statistics are not collected for this target',
      error_statistics: 'Data loading error has occurred',
      error_statistics_update: 'Update',
      add_elements: 'Add elements',
      clear_target: 'Clear target',
      list_copy: 'The list is copied to the clipboard',
      text_copy: 'The text is copied to the clipboard',
      there_are_changes: 'Changes have been made',
      table: {
        name: 'Name',
      },
      copy_modal: {
        campaign: 'Copy target settings',
        creo: 'Copy creative targets',
      },
      prompt: {
        items: {
          source: {
            text: '“Sources” target is an obligatory field.\n\nPlease enable source and fill in the BID field – CPC/CMP/CPA. ',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D1%81%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D0%BA%D0%B8',
          },
          geo_id: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0',
          },
          city: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B3%D0%B5%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%B8-%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%B0',
          },
          site_domain: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD%D1%8B',
          },
          site_uri: {
            link: 'https://terratraf.readme.io/docs/td-target-types#url',
          },
          au: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8',
          },
          os: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B',
          },
          browser: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D1%8B',
          },
          browser_lang: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B9%D1%81%D0%B0-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B0',
          },
          isp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D0%B2%D0%B0%D0%B9%D0%B4%D0%B5%D1%80%D1%8B',
          },
          app: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F',
          },
          private_deals: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D1%8F%D0%BC%D1%8B%D0%B5-%D1%81%D0%B4%D0%B5%D0%BB%D0%BA%D0%B8',
          },
          viewability: {
            link: 'https://terratraf.readme.io/docs/td-target-types#viewability',
          },
          au_bin_8: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-aidata-8',
          },
          au_bin_15: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-uid-20-15',
          },
          au_bin_20: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0-20',
          },
          au_bin_21: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BE%D0%BA-%D0%BF%D0%BE-%D1%81%D1%81%D0%BF-uid-21',
          },
          uniq_show_creative: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          uniq_show_creative_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_creative_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          days_of_week: {
            text: 'You can use only one of two proposed targets by days of the week.\n\nThe targets differ by the ability of time zone change.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc3',
          },
          days_of_week_user: {
            text: 'You can use only one of two proposed targets by days of the week.\n\nThe targets differ by the ability of time zone change.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          hours_of_day: {
            text: 'You can use only one of two proposed targets by time of the day.\n\nThe targets differ by the ability of time zone change.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc3',
          },
          hours_of_day_user: {
            text: 'You can use only one of two proposed targets by time of the day.\n\nThe targets differ by the ability of time zone change.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          uniq_show_camp_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_camp_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          uniq_show_camp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          adgroup: {
            link: 'https://terratraf.readme.io/docs/td-target-types#adgroup-%C2%B7-%D0%BF%D1%80%D0%B5%D1%81%D0%B5%D1%82%D1%8B-google',
          },
          page_context: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BA%D1%81%D1%82-%D1%81%D0%B0%D0%B9%D1%82%D1%8B',
          },
          keywords: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%B2%D1%8B%D0%BC-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D0%BC-%D0%B8-%D1%84%D1%80%D0%B0%D0%B7%D0%B0%D0%BC',
          },
          actions: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D1%82%D0%B0%D1%82%D0%B8%D1%81%D1%82%D0%B8%D0%BA%D0%B0-%D0%BF%D0%BE-%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D1%8F%D0%BC-%D0%B2-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B5',
          },
          video_type: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D1%83-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%8B',
          },
          video_size: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%80%D1%8B-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          socdem: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D0%BE%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE-%D0%B4%D0%B5%D0%BC%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%B8%D0%BD%D0%B3',
          },
          sex: {
            text: 'Select the potential recipients of the ads.\n\nTo reach both men and women, select them both.',
          },
          age: {
            text: 'You can select age from the proposed variants. The age range is 5 years.',
          },
        },
        more_btn: 'More information',
      },
      add_popap_tit: {
        city: 'Add Locations',
        geo_id: 'Add a list',
        keywords: 'Add set',
        os: 'Add Operating Systems',
        browser: 'Add browsers',
        browser_lang: "'Add languages",
        isp: 'Add provider',
        au_bin_8: 'Add Audiences',
        au_bin_15: 'Add Audiences',
        au_bin_20: 'Add Audiences',
        au_bin_21: 'Add Audiences',
        app: 'Add Applications',
        private_deals: 'Add deals',
        viewability: 'Add viewability',
        hour: 'Add by time of day · UTC +3',
        hour2: 'Add by time of day UTC user',
        site_domain: 'Add domains',
        site_uri: 'Add URL',
        page_context: 'Add set',
        video_type: 'Add formats',
        uniq_show_camp_hour: 'Add campaign impression number per hour',
        uniq_show_camp_day: 'Add campaign impression number per day',
        au_bin_info: 'Add Audiences',
        au_bin_lada: 'Add Audiences',
        general: 'Add elements',
        add_popap_smalltit: {
          site_domain: 'Domains',
          au: 'Audiences',
          private_deals: 'Direct deals',
          app: 'Apps',
          isp: 'Internet providers',
          site_url: 'URL',
          browser_lang: 'Browser interface language',
          adgroup: 'AdGroup Google Presets',
          general: 'List',
        },
        add_popap_prompt: {
          title: {
            au: 'Only numbers, more than 1 symbol in the row',
            private_deals:
              'Value format: numbers, symbols, English letters. There must be more than 1 symbol in the row, e.g. BTW-34774-605',
            app: 'Exact match',
            browser_lang:
              'Only English lower case letter, max. 5 symbols in the row.',
            adgroup:
              'Only English lowercase letters and numbers, more than 1 character in the row',
            geo_raw: {
              partOne: 'At first, the country code and a colon (',
              partTwo:
                '), then the city (in Latin letters), each on a new line. Letter case does not matter, check ',
              linkOne: 'Directory',
              linkTwo: 'the cities list',
            },
            city: {
              parOne:
                'You can add several geographic regions to the list at once. Add geo names in Russian using line breaks. The list of names of regions and cities you can find in the ',
              partTwo: ' directory.',
              errorPartOne: "The following geos won't be added: ",
              errorPartTwo: 'City names must fully match the names in the ',
              errorPartThree: '',
              error_dubl: 'In directory you can find name copies: ',
            },
            geo_id: {
              parOne:
                'You can add several geographic regions to the list at once. Add geo names in Russian using line breaks. The list of names of regions and cities you can find in the ',
              partTwo: ' directory.',
              errorPartOne: "The following geos won't be added: ",
              errorPartTwo: 'City names must fully match the names in the ',
              errorPartThree: '',
              error_dubl: 'In directory you can find name copies: ',
              sever_match: 'You can add many countries, regions and cities to the list. Add geos using line breaks. The list of regions and cities you can find in the',
            },
            general: 'OTHER',
            all_app: 'ALL APP',
          },
          text: {
            private_deals: 'e.g: ABC-12345-123',
            browser_lang: 'e.g:  kz, by.',
          },
        },
      },
      geo_id: {
        prompt:
          'Select the regions where you would like to show ads. Please note that by choosing a region, you select all the towns and villages included in it.',
        quickChoice: 'Quick selection',
        search: 'Please enter city or region names...',
        without: 'except for',
        selected: 'Selected locations:',
        showStatistics: 'Show statistics',
        no_statistics: 'No statistics available',
        matches: 'Matches',
        matches4: 'matches',
        matches5: 'matches',
        added_matches: 'There are matches found for your search. Please select geos and continue adding the list.',
      },
      categories: {
        prompt:
          'Select interests from the proposed variants. Please note, by selecting the parent segment, you select all the categories included in this segment.',
        search: 'Enter category name',
        selected: 'Selected interests:',
      },
      city: {
        t1: 'Show at selected locations',
        t2: 'Show everywhere except selected locations',
        t3: 'Use if you want to rotate to the list of cities: for example, Moscow and Moscow region. Important: Please include the cities of the country specified in the targeting GEO Countries',
        t4: 'List the cities to which the rotation should not be carried out. Do not forget to specify the country to be rotated in the GEO targeting: Countries',
      },
      geo: {
        t1: 'Show in selected countries',
        t2: 'Show everywhere except selected countries',
        t3: 'Please use it if you would like to run ads strictly in selected locations. \n Please note, Targeting is an obligatory field. Enable countries where your cities of interest are located.',
        t4: 'Please use it if you do not want to run ads in selected locations.\n Please note, if the countries list is empty and you do not select any country, ads will be displayed worldwide.',
      },
      geo_raw: {
        t1: 'Show at selected locations',
        t2: 'Show everywhere except selected locations',
        t3: 'Use if you want to rotate to the list of cities: for example, Moscow and Moscow region. Important: Please include the cities of the country specified in the targeting GEO Countries',
        t4: 'List the cities to which the rotation should not be carried out. Do not forget to specify the country to be rotated in the GEO targeting: Countries',
      },
      keywords: {
        t1: 'Show by words and key phrases',
        t2: 'Do not show by words and key phrases',
        t3: 'Please add one or more sets with words and key phrases for which impressions should be displayed. The system will search for a complete match of words and the phrase',
        t4: 'List the forbidden words and key phrases in the set, for which impressions should not be displayed. If the words and phrases match, the creative will not be displayed',
        t8: 'Change set',
        t9: 'Delete set',
        t10: 'Keywords and phrases',
        t11: 'Please enter words or key phrases separated by commas',
        t12: 'List of words and phrases',
        t13: 'View the set of words and phrases',
        t14: 'Change the set of words and phrases',
        t15: 'Copy list',
        t16: 'Name',
        t17: 'Save',
        t18: 'Cancel',
      },
      os: {
        t1: 'Show on selected OS',
        t2: 'Show everywhere except selected OS',
        t3: 'From the proposed list of various operating systems, select only those for which this RK is designed. And the impressions will be on the users of the selected OS',
        t4: 'Use if you want to exclude impressions on the user`s installed operating systems. It is enough to select them in the list ',
      },
      browser: {
        t1: 'Show on selected browsers',
        t2: 'Show everywhere except selected browsers',
        t3: 'From the proposed list of browsers, select only those for which this RK is designed. And the impressions will be for users of the selected browsers',
        t4: 'Use if you want to exclude impressions on certain user`s browsers. It is enough to select them in the list',
      },
      browser_lang: {
        t1: 'Show in selected languages',
        t2: 'Show everywhere except selected languages',
        t3: 'The display will only go to those users whose browser interface language matches one of the items you selected in the target list',
        t4: 'The display will go to all users except those whose browser interface language you selected in the target list',
      },
      isp: {
        t1: 'Show on selected providers',
        t2: 'Show everywhere except selected providers',
        t3: 'The display will only go to those users who use the services of the Internet providers selected in the target list',
        t4: 'The display will go to all users, except for those using the services of the Internet providers selected in the target list',
      },
      au_bin_8: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you need “Aidata” audiences from the list to add to display your ad campaign. The impression will go only to those audiences that are selected in the target list ',
        t4: 'The impression will go to all users of the client, except for those that you select in the target list',
      },
      au_bin_15: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you need “UID 2.0” audiences from the list to add to display your ad campaign. The impression will go only to those audiences that are selected in the target list ',
        t4: 'The impression will go to all users of the client, except for those that you select in the target list',
      },
      au_bin_20: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you need audiences of customer users from the list to add to display an ad campaign. The impression will go only to those audiences that are selected in the target list ',
        t4: 'The impression will go to all users of the client, except for those that you select in the target list',
      },
      au_bin_21: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you want to show ads to users in specific groups. Add audience IDs and impressions will only be on them ',
        t4: 'The impression will go to all users, except for those audiences that you add to the list',
      },
      app: {
        t1: 'Show in selected applications',
        t2: 'Show everywhere except selected applications',
        t3: 'Use if you want to specify a list of applications in which ads will be displayed. Add known application IDs to the list ',
        t4: 'Select if you want to show targeted ads in all apps, except for those app IDs that you add to the list',
      },
      private_deals: {
        t1: 'Show on certain deals',
        t2: 'Show everywhere except selected deals',
        t3: 'Use if you have a deal ID. The rotation will be according to previously agreed parameters (price, format, etc.) ',
        t4: 'Use if you want to redeem everything except specific placements from publishers on the basis of direct agreements with him',
      },
      viewability: {
        t1: 'Show at specific visibility',
        t2: 'Show everywhere except for certain visibility',
        t3: 'Use if you want to set the block visibility percentage that SSP will use when rotating the RC',
        t4: 'Select if you want to count impressions when the block visibility does not match any of the visibility percentages selected in the target list',
      },
      uniq_show_creative: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'The creative will be shown to the user no more than the number of times selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown',
      },
      uniq_show_creative_hour: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'The creative will be shown to the user no more often than the number of times per hour selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown for each hour',
      },
      uniq_show_creative_day: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'The creative will be shown to the user no more often than the number of times per day selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown every day',
      },
      days_of_week: {
        t1: 'Show on specific days',
        t2: 'Show everywhere except certain days',
        t3: 'The display will only run if the day of the week in the UTC + 3 time zone corresponds to the items you selected in the target list',
        t4: 'The display will only run if the current day of the week in the UTC + 3 time zone does not match any of the targets specified in the list',
      },
      days_of_week_user: {
        t1: 'Show on specific days',
        t2: 'Show everywhere except certain days',
        t3: 'The display will only run if the day of the week in the user`s time zone matches the items you selected in the target list',
        t4: 'The display will only run if the current day of the week in the user`s time zone does not match any of the targets specified in the list',
      },
      hours_of_day: {
        t1: 'Show at a specific time',
        t2: 'Show everywhere except at certain times',
        t3: 'Use if you want to adjust rotation for different time periods of the day according to UTC + 3 time zone',
        t4: 'Use if you want to prohibit rotation for all time periods of the day according to UTC + 3 time zone, except for the ones you have added to the list',
      },
      hours_of_day_user: {
        t1: 'Show at a specific time',
        t2: 'Show everywhere except at certain times',
        t3: 'The display will only run if the time in the time zone in which the user is located matches the items you selected in the target list',
        t4: 'The display will only run if the current time in the time zone in which the user is located does not match any of the targets specified in the list',
      },
      site_domain: {
        t1: 'Show on selected domains',
        t2: 'Show everywhere except selected domains',
        t3: 'Use if you want to rotate to specific domains that you add to the list',
        t4: 'Use if you want to exclude rotation on specific domains. The display will go to all kinds of domains, except for those selected in the target list ',
      },
      site_uri: {
        t1: 'Show on selected URLs',
        t2: 'Show everywhere except selected URLs',
        t3: 'Use if you want to rotate your ad campaign to specific domains or to all URLs containing full links to sites. Add them to the list ',
        t4: 'Use if you want to prevent rotation of the list of URLs that you add',
      },
      adgroup: {
        t1: 'Show on selected presets',
        t2: 'Show everywhere except selected presets',
        t3: 'The display will only go to those users who match the Google presets selected in the target list',
        t4: 'The display will go to all users except those that fall under the presets you have selected in the target list',
      },
      page_context: {
        t1: 'Show on selected contexts',
        t2: 'Show everywhere except selected contexts',
        t3: 'The display will only go to those sites whose contextual content matches one of the word sets you have selected in the target list',
        t4: 'The display will be shown on all sites except those whose contextual content corresponds to the selected word sets in the target list. Allows you to exclude unwanted content ',
      },
      socdem: {
        t1: 'Show on selected settings',
        t2: 'Show everywhere except the selected settings',
        t3: 'Use if you want to show ads to users based on their gender, age, income. Select settings from the list ',
        t4: 'Use if you need to show ads to all users, except those not suitable for age or gender. It is enough to add settings from the list and there will be no rotation for a specific user group ',
      },
      video_type: {
        t1: 'Show on selected formats',
        t2: 'Show everywhere except the selected formats',
        t3: 'For video ads, select from the list of available formats that are suitable for promoting your video content.',
        t4: 'Use if you want to exclude impressions on certain video ad formats. It is enough to select them in the list. ',
      },
      uniq_show_camp_hour: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'Campaign creatives will be shown to the user no more often than the number of times per hour selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown for each hour',
      },
      uniq_show_camp_day: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'Campaign creatives will be shown to the user no more often than the number of times per day selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown every day',
      },
      uniq_show_camp: {
        t1: 'Show by specific number',
        t2: 'Show everywhere except for a specific number',
        t3: 'Campaign creatives will be shown to the user no more than the number of times selected in the target',
        t4: 'The impression will go to all users, but the selected impression numbers will not be shown',
      },
      au: {
        enter_au: 'Enter audiences',
        add_several: 'You can add several audiences to the list at once using a line break. Add the audience number using numbers only.',
        following_au: 'The following audiences won\'t be added:',
        au_numbers: 'Audience numbers are not found in the list, please update the directory',
      },
      au_bin_info: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you want to show ads to users in specific groups. Add audience IDs and impressions will only be on them ',
        t4: 'The impression will go to all users, except for those audiences that you add to the list',
      },
      au_bin_lada: {
        t1: 'Show on selected audiences',
        t2: 'Show everywhere except selected audiences',
        t3: 'Use if you want to show ads to users in specific groups. Add audience IDs and impressions will only be on them ',
        t4: 'The impression will go to all users of the client, except for those that you select in the target list',
      },
      limits: {
        title: 'Limits',
        set_limits: 'Set limits',
        del_limits: 'Remove limits',
        del_set: 'Reset settings',
        del_set_limit: 'Remove limit settings',
      },
    },
    creative: {
      screenshot: {
        hint: 'While creating a new screenshot, the old screenshot is replaced with a new one',
        screen: 'Screenshot',
        refresh: 'Update status',
        create: 'Take screenshot',
        pending: 'Pending for processing',
        sended: 'Submitted for processing',
        processing: 'Processing',
        error: 'Creation error',
        wait: 'This process takes some time',
        download: 'Download'
      },
      moderation: {
        statuses: {
          approved: 'APPROVED',
          disapproved: 'DISAPPROVED',
          on_moderation: 'On moderation',
          not_checked: 'Creative processing',
          wait_sending: 'Waiting to be sent',
          approved_and_disapproved_for_russia:
            'Allowed everywhere except Russia',
          unknown: 'Unknown',
          ready: 'Ready for moderation',
          awaiting_data_completion: 'Not ready for moderation',
          conditionally_approved: 'Allowed everywhere except Russia',
        },
        modal: {
          google: {
            title: 'Google Moderation',
            description:
              'Contact Google Support to solve a creative moderation problem. To successfully resolve the issue, you need to have ID number of the placed ad:',
            actionBtn: 'Navigate to request form',
          },
          yandex: {
            title: 'Yandex moderation',
            description:
              'Contact Yandex support to solve the problem of creative moderation. To successfully resolve the issue, you need to have ID number of the placed ad. It must be specifed in the letter describing the problem. Address for inquiries: ',
          },
          labelInput: 'Creative ID',
          labelBtn: 'Copy',
          needHelp: 'Need help?',
          collapse: 'Collapse',
          expand: 'Expand',
          variants: {
            variant1: 'Variant 1',
            variant2: 'Variant 2',
          },
        },
      },
      constructor: {
        create: 'Create in builder',
        business_area: 'Business area',
        upload_logo: 'Upload logo',
        select_style: 'Select a style',
        upload_your_logo: 'Upload logo',
        formats: '(PNG, JPEG, JPG max 2 Mb)',
        add_text: 'Add text',
        text_limit: '(max 80 characters)',
        text_of_btn: 'Button text',
        name: 'Name',
        logo: 'Logo',
        style: 'Style',
        illustrations: 'Illustrations',
        illustration: 'Illustration',
        select_a_style: 'Select a style',
        select_illustration: 'Select illustration',
        write_text: 'Write text',
      },
      create_menu: {
        title: 'Creative type',
        TEASER: 'Teaser',
        PUSH: 'Push',
        HTML: 'HTML',
        YANDEX: 'Yandex HTML',
        VIDEO_INLINE: 'Video',
        VIDEO_VAST: 'VAST Video',
        CLICKUNDER: 'Сlickunder',
        MULTI_FORMAT: 'Multiformat',
        COMPANION: 'COMPANION',
        HTML_VIDEO: 'HTML video',
      },
      crea_type: {
        html: 'Type · HTML',
        push: 'Type · PUSH',
        teaser: 'Type · Teaser',
        yandex: 'Type · Yandex HTML',
      },
      allstatrk: 'Ad campaign general statistics',
      settings: {
        set_tit: 'Settings',
        ext_sour: 'Linking external sources',
        ids: 'External ids',
        id_from_ss: 'Enter id from СС',
        enter_date: 'Enter data',
        help_text:
          'For users with administrator rights, a section with a wide range of settings is available, allowing you to set price coefficients by day (for the CPA price type), link external sources, adjust the list of available reports for a campaign, and much more.',
      },
      push_text: {
        t1: 'Icon',
        t2: 'Image',
        t3: 'Name',
        t4: 'Text',
        t5: 'Link to icon',
        t6: 'Allowed formats: png, jpeg, jpg, gif. Max size: 200 Mb',
        t7: 'Min resolution: 80x80 px',
        t8: 'Max resolution: 4096×4096',
        t9: 'Link to image',
        t10: 'Min resolution: 360х240 px. Max resolution: 4096×4096 px.',
        t11: 'Image resize, 2 resolutions: 360×240 px, 492×328 px.',
        t12: 'Delete',
      },
      yandex_html_text: {
        t1: 'Zip archive',
        t2: 'House banner',
        t3: 'Format: ZIP archive. Max size — 150 kb.',
        t4: 'Link to ZIP archive',
        t5: 'Requirements to ad materials',
        t6: '150 kb',
        t7: 'Alternative text',
        t8: 'Yandex brand',
        t9: 'Yandex categories',
        t10: 'Link to house banner',
        t11: 'Formats: jpeg, jpg, png, gif, svg. Max size — 150 kb.',
        t12: 'Min resolution 80 × 80 px. Max resolution — 4096 × 4096 px.\n',
        t13: 'House banner',
        t14: '',
        t15: '',
      },
      widget_text: {
        html_code: 'Banner HTML code',
        size: 'Creative size',
        link: 'Navigation link',
        adomain: 'Domain',
        iab: 'IAB category',
        language: 'Language',
        advertiser_name: 'Advertiser',
        zip_yandex_html: 'Link to archive',
        img_yandex_html: 'Link to house banner',
        alt_text: 'Alternative text',
        yandex_brand: 'Yandex brand',
        yandex_category: 'Yandex categories',
        video_url: 'Link to video',
        duration: 'Duration',
        skip_duration: 'Continue passes',
        video_bitrate: 'Bitrate',
        vast_url: 'Video link (XML file)',
        title: 'Name',
        text: 'Text',
        icon: 'Icon',
        image: 'Image',
        iconMask: 'Icon',
        imgMask: 'Image',
        image_1: 'Image (600 х 600)',
        image_2: 'Image (1080 х 607)',
        ID_ERID: 'ID ERIR',
        check_ERID: 'ERID check',
      },
      video: {
        video_input: {
          label: 'Video (URL)',
          custom_link: 'Link to video',
          hint1:
            'Allowed formats: MP4 (MPEG-4): H.264 video codecs, AAC audio codecs.',
          hint2: 'Recommended size up to 10 Mb.',
        },
        tabs: {
          tab1: 'Main settings',
          tab2: 'Optional Settings',
        },
        fields: {
          video_bitrate: 'Bitrate, kbps',
          video_duration: 'Duration, sec',
          video_duration_pass: 'Pass duration, sec ',
          video_description: 'Description',
          video_show: 'Show (impression tracker URL) ',
          video_show2: 'Show (additional impression tracker URL) ',
          video_click: 'Click (click tracker URL)',
          video_view_0: 'Start (impression tracker URL) ',
          video_view_25: 'FirstQuartile (URL for impression tracker 25%) ',
          video_view_50: 'Midpoint (URL for impression tracker 50%) ',
          video_view_75: 'ThirdQuartile (URL for impression tracker 75%) ',
          video_view_100: 'Complete (URL for impression tracker 100%) ',
          video_skip: 'Skip (Skip tracker URL) ',
          video_mute:
            'Mute (Tracker URL for clicking "Mute" or lowering the volume to 0) ',
          video_unmute: 'Unmute (Unmute tracker URL) ',
          video_pause: 'Pause (Pause tracker URL) ',
          video_rewind:
            'Rewind (Tracker URL for clicking "Rewind" or navigating through the progress bar) ',
          video_resume: 'Resume (Tracker URL for clicking Resume) ',
          video_expand: 'Expand (Tracker URL for expanding player) ',
          video_collapse: 'Collapse (Tracker URL for collapsing player)',
          video_close: 'Close (Tracker URL for closing player) ',
          video_fullscreen: 'Fullscreen (Fullscreen mode tracker URL) ',
          video_exitfs:
            'ExitFullscreen (Tracker URL for exit from a Fullscreen mode) ',
          video_sprogresshow: 'Progress (Tracker URL for progress monitoring)',
        },
        switch: {
          all: 'All fields',
          quartiles: 'Quartiles only',
          show: 'Display only',
        },
      },
      vast: {
        create_hint: {
          title: 'VAST Video',
          text: 'VAST (Video Ad Serving Template) is a specification defined and released by the Interactive Advertising Bureau (IAB) that sets a standard for communication requirements between ad servers and video players in order to present video ads. \nIt is a data structure declared using XML',
        },
        tabs: {
          tab0: 'Link to video (XML file)',
          tab1: 'Main settings',
          tab2: 'Optional Settings',
        },
        fields: {
          video_url: 'Video (URL)',
          video_duration: 'Duration, sec',
          video_duration_pass: 'Pass duration, sec ',
        },
      },
      html: {
        no_tag1: 'HTML code doesn’t contain tag',
        no_tag2: '',
        error: 'Token won’t be displayed on the banner, please contact support',
        copy_code_preview: 'Copy preview code',
      },
      clickunder: {
        create_hint: {
          title: 'Сlickunder',
          text: "Clickunder is a form of pop advertising. Clicking anywhere on the page will open the advertiser's website in a new browser window.",

        },
      },
      crea_statuses: {
        s1: 'Approved',
        s2: 'Creative processing',
        s3: 'Allowed everywhere except Russia',
        s4: 'Unknown',
        s5: 'On moderation',
        s6: 'Disapproved',
        s7: 'Ready for moderation',
        s8: 'Not ready for moderation',
        s9: 'Allowed everywhere except Russia',
        s10: 'Send for moderation',
        s11: 'Copy iframe code',
        s12: 'After changing the settings, please save the block "Type · HTML" to preview current creative version.',
        s13: 'After changing the settings, please save the block "Type · PUSH" to preview current creative version.',
        s14: 'A static placeholder image is displayed in the preview mode',
        s15: 'Preview is not available. Please fill in mandatory fields in "Type · HTML"',
        s16: 'Preview is not available. Please fill in mandatory fields in "Type · PUSH"',
        s17: 'Preview is not available. Please fill in mandatory fields in "Type · Yandex HTML"',
        s18: 'Preview is not available. Please fill in mandatory fields in "Type · VIDEO" block',
        s19: 'Waiting to be sent',
        s20: 'Предпросмотр не доступен. Не заполнены обязательные поля в блоке "Тип · COMPANION"',
        s21: 'Preview is not available. Please fill in mandatory fields in "Type · VIDEO VAST" block',
        NOT_SENT: 'Not submitted for moderation',
        MULTI_FORMAT:
          'After changing the settings, please save the block "Type · Multiformat" to preview current creative version.',
        noAccessPreview:
          'Preview is not available. Please fill in mandatory fields in "Type · ',
      },
      mass_action: {
        modal_test_part1: 'Are you sure you want to',
        modal_test_part2: 'the creatives of campaign',
        modal_test_part3: '',
        modal_test_part4: "clear all creative's targets of campaign",
        successfully_text_part1: 'Creatives have been',
        successfully_text_part2: 'successfully',
        successfully_text_dublicate:
          'Creatives have been duplicated successfully',
        successfully_text_clear: 'Creatives have been cleared successfully',
      },
      js_code_audit: 'JS code audit',
      js_code: 'JS code',
      click_audit_pixel: 'Audit pixel by clicking the banner',
      erir_hint_1: 'Please remember to consider',
      erir_hint_2: 'ID',
      erir_hint_3: '',
      crea_size: 'Size · ',
      cleartarg: "Clear all creative's targets",
      clear_all_target:
        'Are you sure you want to clear all the targets of campaign ',
      stop_all_creatives:
        'Are you sure you want to stop all the creatives of campaign ',
      start_all_creatives:
        'Are you sure you want to launch all the creatives of campaign ',
      clear_done_targ:
        'Are you sure you want to clear all the targets of the creative ',
      fullname: 'Full name',
      shortname: 'Short name',
      fullwidth: 'Full width',
      shortwidth: 'Compact view',
      tables_type: 'Type',
      tables_name: 'Name',
      valid_vendor:
        '{LINK_UNESK} macro is not available in the navigation link',
      errs: {
        e1: 'Data validation error. File failed to meet the upload requirements',
      },
      copy_creative: {
        copy_set: {
          t1: 'Copy settings',
          t2: 'Select creatives where it is needed to copy target',
          t3: 'Copy settings',
          t4: 'It may take a few seconds...',
          t5: 'Creative target settings ',
          t5_2: 'Target and pretarget settings of the creative ',
          t6: ' have been copied to creatives',
          t7: 'Copy with pretarget settings',
        },
        tit: 'Copy settings',
        text_creative:
          'Please select campaigns where you need to copy the creative',
        text: 'Select creatives where it is needed to copy target settings',
        errors: {
          tit: 'Copying error',
          text: 'Not all settings will be copied, some parameters are not available for the creatives below',
          err1: 'Unknown copy error',
        },
        succes: {
          t1: 'Creative ',
          t2: ' has been successfully copied',
          t3: ' to campaigns',
          t4: 'Creatives ',
          t5: ' has been successfully copied',
        },
        copying_tit: 'Copying creative',
        copying_text: 'It may take a few seconds...',
      },
      dubl_creative: {
        t1: 'The copy of the creative ',
        add_eng_prefix_one: 'copy of',
        add_eng_prefix_many: 'copies of',
      },
      del_crea: {
        t1: 'Creative ',
        t2: ' has been deleted',
      },
      cop: 'Copy settings',
      clea: 'Clear all targets',
      none: {
        no1: 'You have no creatives.',
        no2: 'Click to create.',
        none_text: 'You can create a creative by using',
        none_text_of: 'video, image, html code',
      },
      type: {
        type: 'Type · ',
        teaser: 'Teaser',
        video: 'Video',
        video_vast: 'VAST Video',
        clickunder: 'Сlickunder',
      },
      header_menu: {
        back: 'Back',
        settings: 'Settings',
        targetings: 'Targeting',
        basic_settings: 'Basic settings',
      },
      use_VPAID: 'Use VPAID',
      create_btn: 'Create creative',
      creative_was_created: 'Creative created',
      creative_was_edited: 'Last modified',
      creative_requare: 'Required to fill',
      creative_text: 'Creative',
      search: 'Search...',
      Name: 'Name',
      Impressions: 'Imp',
      cpm: 'CPM',
      Clicks: 'Clicks',
      cpc: 'CPC',
      ctr: 'CTR',
      Amount_spent: 'Spend',
      Moderation: 'Moderation',
      DocumentationGoogle: 'Google Docs',
      Status: 'Status',
      stat_act: 'Running',
      stat_pause: 'Paused',
      creative_prompt: {
        creative_text: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2',
        },
        type: {
          HTML: {
            link: 'https://terratraf.readme.io/docs/td-creatives-htmlbanner#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-html-%D0%B1%D0%B0%D0%BD%D0%BD%D0%B5%D1%80%D0%B0',
          },
          PUSH: {
            link: 'https://terratraf.readme.io/docs/td-creatives-push',
          },
          TEASER: {
            link: 'https://terratraf.readme.io/docs/td-creatives-teaser#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-%D1%82%D0%B8%D0%B7%D0%B5%D1%80%D0%B0',
          },
          VIDEO_INLINE: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          VIDEO_VAST: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video-vast#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-vast',
          },
          MULTI_FORMAT: {
            link: 'https://terratraf.readme.io/docs/td-creatives-multiformat',
          },
          COMPANION: {
            link: '',
          },
        },
        additional_settings: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8',
        },
      },
      create_creative: {
        newTitle: 'New creative',
        success: {
          t1: 'Creative ',
          t2: ' successfully created',
        },
        tizmacr: {
          m1: {
            t1: 'Macro · campaign ID',
            t2: 'Macro will be replaced by campaign ID, e.g.:',
          },
          m2: {
            t1: 'Macro · creative ID',
            t2: 'Macro will be replaced by creative ID, e.g.',
          },
          m3: {
            t1: 'Macro · domain ID',
            t2: 'Macro will be replaced by domain ID, e.g.',
          },
          m4: {
            t1: 'Macro · click ID',
            t2: 'Macro will be replaced by click ID, e.g.',
          },
        },
        create_tit: 'Create creative',
        Advertiser_name: 'Advertiser',
        Please_fill:
          'Please fill in this field to submit creative for Google approval process',
        Name: 'Name *',
        Creative_name: 'Creative',
        select_type: 'Select type ...',
        symbols: ' characters',
        type_tit: 'Type',
        type_tit_html: 'HTML',
        File: 'File',
        Supported_format: 'Supported format: zip archive. Max size: 6 Mb',
        Max_size: '',
        Add_archive: 'Add archive',
        HTML_Code: 'HTML Code',
        Actions: 'Actions',
        Convert_code: 'Convert a code',
        Add_macros: 'Add macros',
        Size_px: 'Size, px',
        Please_select: 'Please select',
        Set_sizes: 'Set sizes',
        Width: 'Width',
        Height: 'Height',
        select_from_list: 'Select from the list',
        Additional_settings: 'Additional settings',
        URL_settings: 'URL settings',
        Navigation_link: 'Navigation link',
        Extension_link: 'Additional navigation link and localization',
        Link: 'Link',
        Domain: 'Destination domain',
        Domain_address: 'Domain address',
        Optional_URL: 'Optional settings',
        Pixel_audit: 'Pixel audit',
        Optional_pixel: 'Optional pixel audit',
        Categories: 'Categories',
        CategoriesAndLanguage: 'Categories and language',
        Language: 'Language',
        optimization: {
          title: 'Optimization algorithm',
          algorithm: 'Algorithm',
          default: 'Not selected',
          max_ctr: 'Optimization by CTR',
          min: 'Min value',
          max: 'Max value',
        },
        Creative_preview: 'Creative preview',
        Preview: 'Preview',
        To_preview:
          'To preview, you need to create creative, after that the button will be active',
        not_available: 'Preview is not available.',
        went_wrong: 'Something went wrong.',
        try_to_refresh: 'Please try to refresh this page.',
        This_format:
          'This format supports 3 variants how to create a creative:',
        Upload_zip: 'Upload zip archive',
        Import_html: 'Import html code',
        animation: 'Animation',
        type_jest: 'Severity',
        type_erotick: 'Erotic',
        ani_yes: 'Yes',
        ani_no: 'No',
        ertype: {
          t1: 'No',
          t2: 'Light',
          t3: 'Not too hard',
          t4: 'Hard',
          t5: 'With no sex',
          t6: 'Body parts',
          t7: 'Soft-core',
          t8: 'Borderline erotic',
          t9: 'Pornography',
        },
        show_html: 'show (impression tracker url html)',
      },
      delete_creative_modal: {
        delete_tit: 'Delete creative',
        delete_quest: 'You are going to delete',
      },
      no_result_for_filter: 'No results found for this filter',
      tizer_word: 'Teaser',
      targeting_settings: 'Uses custom creative targeting settings',
      pretargeting_settings: 'Own pretargeting settings are enabled',
      targetAndPretarget_settings:
        'Own pretargeting and targeting settings are enabled',
      no_selected_pretarget: 'No selected pretarget settings found',
      creasmall_set: {
        linktoicon_word: 'Link to icon',
        icon_word: 'Icon',
        teaser_formats1:
          'Formats: png, jpeg, jpg, gif. The maximum size is 6 Mb.',
        teaser_formats2:
          'Minimum resolution 80 × 80 px. Maximum - 4096 × 4096px.',
        linktoimage_word: 'Link to image',
        image_word: 'Image',
        teaser_formats3:
          'Accepted formats: png, jpeg, jpg, gif. The maximum size is 6 MB.',
        teaser_formats4:
          'The minimum resolution is 100 × 100 px. The maximum is 4096 × 4096 px.',
        html_format1: 'Allowed formats: png, jpeg, jpg, gif. Max size: 6 Mb.',
        html_format2: 'Min resolution: 100×100. Max resolution: 4096×4096',
        title_word: 'Title',
        text_word: 'Text',
        upload_comp: 'Upload from computer',
        upload_word: 'Upload',
        push_formats1:
          'Accepted formats: png, jpeg, jpg, gif. The maximum size is 6 Mb.',
        push_formats2: 'Allowed resolution 192 x 192 pixels.',
        push_formats3: 'Allowed resolution 360 x 240 pixels.',
        multuformat_formats1:
          'Accepted formats: png, jpeg, jpg. The maximum size is 2 Mb.',
        multuformat_formats2: 'The allowed resolution is 256 x 256 pixels.',
        multuformat_formats3: 'The allowed resolution is 600 x 600 pixels.',
        multuformat_formats4: 'The allowed resolution is 1080 x 607 pixels.',
        type_hint: {
          HTML: 'To view, you need to fill in the "Type · HTML" section and create a creative, after that the button will become active.',
          PUSH: 'To view, you need to fill in the "Type · Push" section and create a creative, after that the button will become active.',
          MULTI_FORMAT:
            'To view it, you need to fill in the "Type · Multiformat" block and create a creative, after that the button will become active.',
          YANDEX:
            'To view, you need to fill in the "House banner" section and create a creative, after that the button will become active',
          VIDEO_INLINE:
            'To view, you need to upload a video and create a creative, then the button will become active.',
        },
        multiformat: {
          type_of_btn: 'Button type',
          legal_info_title: 'Legal Information',
          icon: 'Icon (256 x 256)',
          image_1: 'Image (600 х 600)',
          image_2: 'Image (1080 х 607)',
          title_hint:
            'Enter a title for your ad. You cannot start a sentence with a small letter or symbol. Use a capital letter at the beginning of a sentence.',
          text_hint:
            'Enter your ad text. Please note that there can be only one exclamation mark in an ad: either in the title or in the text. You cannot start a sentence with a small letter or symbol. Use a capital letter at the beginning of a sentence.',
          legal_info:
            'In the Legal information field, add information about the seller (OGRN, TIN, etc.) or leave this field blank.',
          type_btn_hint:
            'Call to action button. Use appropriate Button Text to increase the appeal of your ad.',
        },
        type_html_hint:
          'To view, you need to fill in the "Type · HTML" section and create a creative, after that the button will become active.',
        type_push_hint:
          'To view, you need to fill in the "Type · Push" section and create a creative, after that the button will become active.',
        type_multiformat_hint:
          'To view it, you need to fill in the "Type · Multiformat" block and create a creative, after that the button will become active.',
        type_yandex_hint:
          'To view, you need to fill in the "House banner" section and create a creative, after that the button will become active',
        type_video_hint:
          'To view, you need to upload a video and create a creative, then the button will become active.',
        type_video_vast_hint:
          'To view, you need to upload a video VAST and create a creative, then the button will become active.',
        type_companion_hint: 'To view, you need to upload the video with html code and to create the creative, then the button becomes active.',
        notarg_text: 'To target, you need to create creative',
        zip_link: 'ZIP-archive link',
        zip_arr: 'ZIP archive',
        zip_arrrull: 'Requirements to ad materials',
        yand_format: 'Format: ZIP archive. The maximum size is 150 kb.',
        yand_link_img: 'Link to a stub picture',
        yand_stub: 'Placeholder picture',
        yand_formats1:
          'Formats: jpeg, jpg, png, gif, svg. The maximum size is 150 kb.',
        yand_formats2:
          'Minimum resolution 80 × 80 px. The maximum is 4096 × 4096px.',
        yand_alt_text: 'Alternative text',
        yand_brand: 'Yandex brand',
        yand_cat: 'Yandex categories',
      },
      mass_editing: 'Mass editing',
      set_url_incl_links: 'URL settings are applied only to the creatives containing these links.',
      liks_stays: 'Links not added to the mass editing will remain unchanged',
      will_be_changed: 'Only the fields containing added values will be changed',
      error: 'Failed to save mass editing',
      add_url: 'Add URL for editing',
      enter_link: 'Enter link',
      link_will_clean: 'Field will be cleaned',
      discard_changes: 'Discard changes',
      all_changes_saved: 'All changes have been saved in selected {{value}} creatives ',
      used_in_creatives: 'Used in creatives: {{creos}} only',
      the_field_be_deleted: 'The field content will be deleted in selected creatives',
      eridWarning_confirm: 'ERID is not registered in DSP! Do you confirm its use in ad running?',
      eridWarning_what_show: 'You are using ERID not registered in DSP! INN and name of the counterparty won\'t be displayed in the ad block.',
    },
    containers_page: {
      tit: 'Containers',
      name: 'Name',
      size_aut: 'Audience volume',
      size_aut_day: 'Daily audience volume',
      back: 'Back',
      date: 'Date',
      stat_tit: 'Statistics',
      download: 'Download',
      more_info: 'For more information please follow the link',
      containers_none: 'You haven’t any containers yet',
      not_linked: 'Containers are not linked',
    },
    search_by_id_popup: {
      filter: {
        placeholder: 'Filter',
      },
      search: {
        placeholder: 'Campaign / Creative ID',
        clear: 'Clear',
      },
      empty_list: 'No results were found for this request',
    },
    type_parther: {
      ORGANIZATION: 'Organisation',
      AGENCY: 'Agency',
      CLIENT: 'Client',
    },
    report: {
      download: 'Download report',
    },
    translations: {
      title: 'Status',
      title_hint_camp: 'Campaign broadcast status.',
      title_hint_creo: 'Ad broadcast status.',
      title_hint_link: 'Learn more',
      status_paused: 'Organization status is paused',
      agency_no_start: 'Agency is not launched',
      client_not_start: 'Client is not launched',
      campaign_stoppen: 'Campaign is stopped',
      outdated_period: 'Outdated ad running period',
      zero_target: 'Zero optimization target',
      balance_exhausted: 'Balance is exhausted',
      campaign_ready: 'Campaign is ready for launching creatives',
      adv_uploaded: 'Advertisement is uploaded for broadcasting',
      adv_not_uploaded:
        'Advertisement is not uploaded. There will be no impressions.',
      ad_stopped: 'Ad is stopped',
      ad_not_pass: 'Ad didn’t pass moderation',
      broadcast: 'Broadcasting',
      no_broadcast: 'Not broadcasting',
      title_widget: 'Broadcast status',
      no_results_status: 'No results found for this broadcasting status',
      contact_adnim_toggle:
        'Please contact the administrator to change the status.',
      check_toggle: 'For launching, please click toggle button.',
      check_toggle_need_help: 'If you need help, contact the administrator.',
      check_toggle_check_settings:
        'Please check the settings of the creative and submit again for moderation.',
      check_date:
        'Please check the start and the end date in campaign settings. For more details, please follow the ',
      check_date_link: 'link.',
      check_price:
        'Please check the price for the specified optimization type in campaign settings. For more details, please follow the ',
      check_price_link: 'link.',
      no_funds:
        'There are no funds on the client’s account. Please contact the administrator',
      prepare_creatives:
        'You need to prepare creatives: create them, submit for moderation and fill in the required targets.',
      campaign_no_ready: 'Campaign is not ready for launching creatives.',
      dsp_disable: 'Disabled in DSP',
      no_impressions: 'Disabled in DSP, there will be no impressions',
      camp_stop: 'Campaign was stopped in DSP',
      call_admin: 'Please contact admin',
      client_stop: 'Client was stopped in DSP',
      ad_stop: 'Ad was stopped in DSP',
      dsp_problem: 'There are some technical difficulties in DSP',
      fixed_soon: 'Everything will be fixed soon',
      ad_is_run: 'Please note: Ad is running in DSP',
      camp_is_run: 'Please note: Campaign is running in DSP',

      error_creo: 'There is an error in creative’s data',
      contact_adnim: 'Please contact the administrator.',
      geo_target_empty: 'GEO target is not filled in',
      set_local:
        'Please check creative’s targets and specify it. For more details, please follow the',
      set_local_link: 'link.',
      source_target_empty: 'Sources target is not filled in',
      select_ssp:
        'Please check creative’s targets and select SSP. For more details, please follow the ',
      select_ssp_link: 'link.',

      profile_settings: 'Show broadcasting status',
      profile_set_info:
        'If campaigns are created for ad impressions, enable the setting. You will see the broadcasting status of the campaign and the advertisement on the TD pages.',

      see_statistics:
        'If the campaign/creative passed all the checks, you will see statistics with the number of impressions and/or clicks. If there are no impressions for more than an hour, contact the administrator.',
      prepare_creo:
        'You need to prepare creatives: create them, submit for moderation and fill in the required targets',
    },
    ord: {
      title: 'Advertising data operator',
      registration_in_ord: 'registration to the advertising data operator',
      сounterparties_title: 'Counterparties',
      сounterparties_search: 'Search by INN / name of legal entity',
      counterparte_create: 'Create a counterparty',
      сounterparties_none: 'You have no counterparties yet. Click to create a new one',
      contracts_title: 'Original contracts',
      counterparte_creating: 'Creating a counterparty',
      parameters: 'Parameters',
      type: 'TYPE *',
      name_of_legal: 'NAME OF LEGAL ENTITY*',
      synonyms_name: 'SYNONYMS OF THE NAME',
      inn: 'INN / ALTERNATIVE',
      synonyms_name_1: 'SYNONYMS OF THE NAME (SEPARATED BY COMMA)',
      name_for_export: 'NAME FOR EXPORTED DATA',
      synonyms_enter: 'Enter synonyms',
      contract_create: 'Create contract',
      contract_search: 'UUID / Contract number / External ID / INN of contractor / INN of customer',
      customer_select: 'Select a customer',
      contractor_select: 'Select a contractor',
      contract_delete: 'Delete contract',
      want_to_del: 'Are you sure you want to delete the contract <bold>{{title}}</bold>?',
      contract_deleted: 'The contract <bold>{{title}}</bold> has been deleted',
      contacts_none: 'You have no contracts yet. Click to create a new one',
      creating_contact: 'Creating a contract',
      contact_info: 'Contract information',
      contragents_info: 'Сounterparty information',
      registered: 'Registered in advertising data operator',
      original_contract: 'Original contract ',
      contract_data: 'Contract data',
      additional_contract_data: 'Additional contract data',
      inn_name: 'INN / Name',
      contractor: 'Contractor',
      contract_number: 'contract number*',
      enter_number: 'Enter number',
      contract_date: 'contract date*',
      enter_date: 'Enter date',
      contract_type: 'contract type*',
      select_type: 'Select a type',
      information_on_contract: 'Information on contract subject*',
      contract_amount: 'contract amount, ₽ *',
      transferring_money: 'transferring money',
      select_transfer: 'Select where to transfer',
      actions: 'Actions taken by the intermediary*',
      report_contract: 'report contract',
      obligation_to_register: 'Obligation to register and report creatives by contractor',
      inc: 'incl. VAT',
      contact_created: 'Contract <bold>{{title}}</bold> been created successfully',
      exported: 'Exported to the ERIR',
      revenue_contract: 'Revenue contract',
      parental_contract: 'Parental contract',
      financial_closure: 'Financial closure will be conducted under this contract.',
      impossible_export: 'Impossible to export to ERIR',
      send: 'Send to ERIR',
      export_planned: 'Export is planned',

      original_agreement: 'If the client\'s original agreement is of the "Additional Agreement" type, it is required to transfer the information on the corresponding original agreement(s) as well. Required to be filled in only for additional agreements.',
      impossible_send: 'Impossible to send',
      pending: 'Pending',
      legal_entity: 'Legal entity',
      individual_entity: 'Individual entity',
      sole_trader: 'Sole trader',
      foreign_individual_entity: 'Foreign individual entity',
      foreign_legal_entity: 'Foreign legal entity',
      registration_number: 'Registration number*',
      identification_number: 'Taxpayer identification number or its equivalent*',
      counterparty_created: 'Counterparty <bold>{{title}}</bold> has been created successfully',
      inn_or: 'INN',
      status: 'Status',
      customer: 'CUSTOMER',
      waiting_export: 'Waiting for export',
      successfully: 'Successfully',
      error: 'Error',
      date: 'DATE',
      td_clients: 'TD ClIENTS*',
      td_agencis: 'TD AGENCIES*',
      intermediary_contract: 'Intermediary contract',
      other: 'Other',
      agent_acts: 'Agent acts on behalf of advertiser distributor',
      yes: 'Yes',
      no: 'No',
      contract_info: 'Contract',
      clients: 'Clients*',
      no_del_contracts: 'There are no deleted contracts',
      fio: 'Full name',
      actions_for_dist: 'Actions for ad distribution',
      contracts_concl: 'Contracts conclusion',
      commercial_rep: 'Commercial representation',
      agent_acts_behalf: 'Agent acts on behalf of advertiser',
      service_contract: 'Service contract',
      additional_agreement: 'Additional agreement',
      self_advertisement: 'Self-advertisement',
      representation: 'Representation',
      distribution_org: 'Distribution organisation',
      intermediation: 'Intermediation',
      distribution: 'Distribution',
      registration_country: 'Registration country',
      mobile_phone: 'MOBILE PHONE NUMBER*',
      unknow_ord: 'Unknown advertising data operator',
      contracts: {
        title: 'OKVED',
        code: 'OKVED codes for ad objects',
        hint1: 'Field is required to be filled in if the customer in the initial contract is a natural person or a foreign counterparty',
        hint2: 'OKVED will be automatically applied for those creatives where this initial contract is used',
        placeholder_search: 'Search by contract number / INN ',
        one: 'Initial contract',
        open: 'Open contract ',
      },
      errors: {
        required_field: 'Required field',
        already_exists: 'Counterparty with this INN already exists',
        inn_invalid: 'INN is invalid',
        should_not_match: 'Customer and contractor should not match',
        min_6_characters: 'Minimum 6 characters',
        max_40_characters: 'Maximum 40 characters',
        min_1_character: 'Minimum 1 character',
        max_255_characters: 'Maximum 255 characters',
        max_1000_characters: 'Maximum 1000 characters',
        min_value_0: 'Minimum value is 0',
        max_value_exceeded: 'Maximum value has been exceeded',
        incorrect_value: 'Incorrect value',
        field_only_digits: 'This field must contain only digits',
        fill_field_or_epay: 'Fill in this field or EPAY',
        fill_field_or_phone: 'Fill in this field or "Mobile phone number"',
        field_only_latin_letters: 'This field must contain only digits and Latin letters',
        fill_filed_or_equivalent: 'Fill in this filed or "Taxpayer identification number or its equivalent"',
        fill_field_or_number: 'Fill in this field or "Registration number"',
        taxpayer_equivalent: 'Taxpayer identification number or its equivalent',
        customer_contract_type: 'Customer and contractor should match for "Self-advertisement" contract type',
        unsupported_contract: 'Unsupported contract type for advertising data operator',
        cus_refer: 'Customer and contractor should refer to the same advertising data operator as the contract',
        should_written: 'Full name should be written in Cyrillic characters with a single space or a dash between words',
        okved_field: 'OKVED field is required',
      }
    },
    found: 'Found',
    save_btn: 'Save',
    cancel_btn: 'Cancel',
    create_btn: 'Create',
    select_btn: 'Select',
    btn: 'Button',
    ok_btn: 'ОК',
    continue_btn: 'Continue',
    copy_btn: 'Copy',
    edit_btn: 'Edit',
    add_btn: 'Add',
    no_result: 'Not found',
    apply_btn: 'Apply',
    stop: 'Stop',
    stopped: 'stopped',
    launched: 'launched',
    deleted: 'deleted',
    start: 'Run',
    search_btn: 'Search',
    clean_btn: 'Clear',
    more_btn: 'More details',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    email: 'Email',
    selected: 'Selected',
    delete_btn: 'Delete',
    update_btn: 'Refresh',
    change_btn: 'Change',
    in_archive_btn: 'Archive',
    archive_title_to: 'Archive',
    archive_title_from: 'Extract from the archive',
    from_archive_btn: 'Extract',
    retrieved: 'extracted from the archive',
    archived: 'archived',
    expand_btn: 'Expand',
    collapse_btn: 'Collapse',
    duplicate_btn: 'Duplicate',
    statistics_btn: 'Statistics',
    download_btn: 'Download',
    show_btn: 'Show',
    copy_to_another_campaign_btn: 'Copy to another campaign',
    wider_then: 'Full width',
    wider_center: 'Compact view',
    disable_edit: 'You have a restriction on editing this block',
  },
};
