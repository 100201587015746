import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePartnerBalanceInfo } from 'domains/campaigns/model/selectors';
import { TCollapseKey } from '../../domains/storage/model/types';
import { useStorageCollapseWrappersInfo } from '../../domains/storage/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';
import { setCollapseWrappers } from '../../domains/storage/model/reducer';

type TFUseCollapseProps = {
  fetcher?: () => void;
  nameWrapper: TCollapseKey;
  isLoading?: boolean;
};

type TFUseCollapse = (props: TFUseCollapseProps) => {
  isCollapse: boolean;
  handleCollapse: (collapse: boolean) => void;
};

export const useCollapse: TFUseCollapse = ({
  fetcher,
  nameWrapper,
  isLoading,
}) => {
  const dispatch = useDispatchApp();
  const collapseWrappers = useStorageCollapseWrappersInfo();
  const location = useLocation();
  const { data: balanceData } = usePartnerBalanceInfo();
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const isCollapse = useMemo<boolean>(
    () => collapseWrappers.includes(nameWrapper),
    [collapseWrappers],
  );

  const handleCollapse: ReturnType<TFUseCollapse>['handleCollapse'] = (
    collapse,
  ) => {
    if (!collapse) {
      dispatch(
        setCollapseWrappers([
          ...collapseWrappers.filter((key) => key !== nameWrapper),
        ]),
      );
    } else {
      dispatch(setCollapseWrappers([...collapseWrappers, nameWrapper]));
    }
  };

  useEffect(() => {
    if (!isCollapse && fetcher && !isLoading && !isFetched) {
      if (
        nameWrapper !== 'CAMPAIGN_TRANSLATIONS' &&
        nameWrapper !== 'CREATIVE_TRANSLATIONS'
      ) {
        fetcher();
        setIsFetched(true);
      }
    }
  }, [isCollapse, isLoading, location]);

  useEffect(() => {
    if (!isCollapse && fetcher && !isLoading) {
      if (
        nameWrapper === 'CAMPAIGN_TRANSLATIONS' ||
        nameWrapper === 'CREATIVE_TRANSLATIONS'
      ) {
        if (balanceData) {
          fetcher();
        }
      }
    }
  }, [isCollapse, isLoading, location, balanceData]);

  return {
    isCollapse,
    handleCollapse,
  };
};
